import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 100px 0;
  // background: var(--surface-card);

  .heading {
    a {
      margin-top: 5px;
      display: block;
      color: var(--primary-color);
      text-decoration: none;
    }
  }

  .apexcharts-legend {
    left: 0px !important;
    width: 50%;

    .apexcharts-legend-series {
      text-align: end;

      .apexcharts-legend-marker {
        display: none;
      }

      .apexcharts-legend-text {
        margin: 0;
        color: #ffffff99 !important;
        font-weight: 400;
        font-family: sans-serif;
        letter-spacing: 1px;
      }
    }
  }

  .apexcharts-track {
    path {
      stroke: #ffffff06;
    }
  }

  @media (max-width: 576px) {
  }
`;
