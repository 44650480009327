import { Container } from 'components/container';
import { Section } from './index.styled';
import { SectionHeader } from 'components/Section';
// import ReactApexChart from 'react-apexcharts';
// import { ApexOptions } from 'apexcharts';

const TokenInfo = () => {
  // var labels = ['Foundations & Community', 'Team', 'Private Sale', 'Public Sale', 'Insiders'];
  // var data = [38.9, 30, 14.1, 12, 5];

  // const options: ApexOptions = {
  //   plotOptions: {
  //     radialBar: {
  //       offsetY: 0,
  //       startAngle: 0,
  //       endAngle: 270,
  //       hollow: {
  //         margin: 5,
  //         size: '10%',
  //         background: 'transparent',
  //         image: undefined,
  //       },

  //       dataLabels: {
  //         name: {
  //           show: false,
  //         },
  //         value: {
  //           show: false,
  //         },
  //       },
  //     },
  //   },
  //   colors: [
  //     '#dd2c00',
  //     '#00cfe5',
  //     '#ffa000',
  //     '#c200db',
  //     '#e80074',
  //     '#282176',
  //     '#4b5076',
  //     '#96a6a6',
  //     '#00838f',
  //     '#00bfa5',
  //   ],
  //   labels,
  //   legend: {
  //     show: true,
  //     floating: true,
  //     fontSize: '14px',
  //     position: 'left' as any,
  //     offsetX: 0,
  //     offsetY: 10,
  //     labels: {
  //       useSeriesColors: false,
  //     },
  //     formatter: function (seriesName, opts) {
  //       return seriesName + ',  ' + opts.w.globals.series[opts.seriesIndex] + '%';
  //     },
  //     itemMargin: {
  //       vertical: 5,
  //     },
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         legend: {
  //           show: false,
  //         },
  //       },
  //     },
  //   ],
  // };

  return (
    <Section>
      <Container>
        <div className="grid">
          {/* 
          // Not sure if this will be reused - Ricky B
          <div className="col-12 md:col-6 text-center">
            <SectionHeader
              className="mb-5"
              label="<span className='crwd-font'>drop<b>unit</b>™</span> Offering"
              text="<a href='#' class='text-center'>(DROP Protocol™)</a>"
            />
            <ReactApexChart options={options} series={data} type="radialBar" height={390} />
          </div> */}
          <div className="col-12 md:col-8 mx-auto">
            <SectionHeader
              className="heading-right"
              label="The <span className='crwd-font'>drop<b>unit</b>™</span>"
              title="The <span className='crwd-font'>drop<b>unit</b>™</span> is our cornerstone to bringing a new era to the internet and finance"
              text="<span>While traditional digital business ecosystems like Amazon and Google extract the most value and keep it. Our Vogon-powered Blockchain ecosystem shares it.</span><span> Our Vogon Blockchain Utility Token is called a DROPunit™ because it represents a measurement of transactional energy required for an activity on our blockchain and a cryptographic unit representing silver. Unique to our Blockchain Ecosystem, our <span className='crwd-font'>drop<b>unit</b>™</span> allows members to perform practical actions on <span className='crwd-font'>protocol</span>. Its use case is simple, to establish the utility of worth with natural asset backing.</span>"
            />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default TokenInfo;
