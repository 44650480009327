import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  background: var(--surface-card);

  .p-accordion {
    .p-accordion-tab {
      box-shadow: none;

      .p-accordion-header-link {
        font-weight: 500;
        font-size: 1rem;
        padding: 15px;
        border: 0 none;
        color: var(--text-color);
        background: transparent;
        border-radius: 0px !important;
        transition: none;
        border-top: 1px solid var(--surface-card) !important;
      }

      &:last-child {
        .p-accordion-header-link {
          border-bottom: 1px solid var(--surface-card) !important;
        }
      }

      .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background: var(--surface-card);
        border-bottom: 0px solid var(--surface-card) !important;
      }

      .p-accordion-content {
        background: var(--surface-card) !important;
        text-align: justify;
        p {
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }

  .img-responsive {
    max-width: 280px;
    @media (min-width: 960px) {
      max-width: initial;
    }
  }
  @media (max-width: 576px) {
  }
`;
