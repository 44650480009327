import React, { useState } from 'react';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import Button from 'components/button';
import { Container } from 'components/container';
import { InputNumber } from 'primereact/inputnumber';
import Input from 'components/input';
import { GradiantText } from 'components/text';
import { useClientContext } from 'contexts/ClientContext';
import { Sdk } from 'graphql/generated/graphqlRequest';
import { Section } from './index.styled';
import { RegisterFormResolver } from './registerResolver.yup';
import { IRegisterForm } from './hookforms';
import PhoneInput from 'components/phoneInput';
import { Dialog } from 'primereact/dialog';
import Redirect from 'components/redirect';
import TokenCalculator from 'components/token-calculator';
import { useIsMobile } from 'utils/isMobileHook';

interface RegisterFormProps {
  light?: boolean;
}

export async function submitForm(sdkClient: Sdk, data: Record<string, string | any>): Promise<void> {
  const result = await sdkClient.unitPurchaseRequest({ formData: data });
  if (result.unitPurchaseRequest.error) {
    throw new Error(result.unitPurchaseRequest.error);
  }
  return;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ light = false }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [investValue, setInvestValue] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, string>>({});

  const isMobile = useIsMobile();
  const { sdkClient, affiliateCode } = useClientContext();

  const formMethods = useForm<IRegisterForm>({ resolver: RegisterFormResolver, mode: 'all' });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = formMethods;

  const onSubmit = (data: IRegisterForm) => {
    console.log('onSubmit', data);
    setFormData({ ...data });
    setShowDialog(true);
  };
  const onSendInvestment = () => {
    submitForm(sdkClient, {
      affiliate_code: affiliateCode || '',
      amount: `${investValue}`,
      ...formData,
    })
      .then(() => {
        setTimeout(() => {
          window.open('https://portal.dropprotocol.io', '_self');
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }, 4000);
      })
      .catch(error => {
        window.alert(error.message);
        setLoading(false);
      });
    setLoading(true);
  };

  const onHideDialog = () => {
    reset();
    setShowDialog(false);
  };

  

  return (
    <>
      <Dialog style={{ width: isMobile ? '100%' : '400px' }} visible={showDialog} onHide={onHideDialog}>
        <div className="flex flex-column gap-3">
          {!loading ? (
            <>
              <GradiantText className="text-center mb-4">Enter your pledge amount</GradiantText>
              <InputNumber
                value={investValue}
                inputId="currency-us"
                placeholder="$0.00"
                onValueChange={e => {
                  setInvestValue(e.value);
                }}
                mode="currency"
                currency="USD"
                locale="en-US"
              />
              <Button label="Send invest" className="justify-content-center" onClick={() => onSendInvestment()} />
            </>
          ) : (
            <>
              <Redirect loading={loading} />
            </>
          )}
        </div>
      </Dialog>

      <Section className={light && 'light'}>
        <Container fluid>
          <div className="grid m-0">
            <div className="col-12 md:col-6 md:col-offset-3">
              <TokenCalculator />
              {/* <GradiantText className="text-center">RESERVE YOUR UNITS TODAY!</GradiantText> */}
              {isMobile ? (
                <img className="my-7 w-8" src="/assets/images/register-mobile.png" alt="" />
              ) : (
                <img className="my-7 w-full" src="/assets/images/drop/drop_payments.jpg" alt="" />
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-column align-items-center justify-content-center gap-3">
                  <div className="w-full">
                    <Input
                      className={errors.first_name?.message ? 'p-invalid' : ''}
                      control={control as unknown as Control<FieldValues, any>}
                      defaultValue=""
                      name="first_name"
                      label="First Name"
                      autoComplete="given-name"
                    />
                    {errors.first_name?.message && (
                      <small id="username2-help" className="p-error block text-left mt-2 mb-2">
                        {errors.first_name.message}
                      </small>
                    )}
                  </div>
                  <div className="w-full">
                    <Input
                      className={errors.last_name?.message ? 'p-invalid' : ''}
                      control={control as unknown as Control<FieldValues, any>}
                      defaultValue=""
                      name="last_name"
                      label="Last Name"
                      autoComplete="family-name"
                    />
                    {errors.last_name?.message && (
                      <small id="username2-help" className="p-error block text-left mt-2 mb-2">
                        {errors.last_name.message}
                      </small>
                    )}
                  </div>
                  <div className="w-full">
                    <Input
                      className={errors.email?.message ? 'p-invalid' : ''}
                      control={control as unknown as Control<FieldValues, any>}
                      defaultValue=""
                      name="email"
                      label="Email"
                      autoComplete="email"
                    />
                    <p></p>
                    <small className="block text-left mt-2 mb-2">
                      Use the same email you use for{' '}
                      <a href="https://crwd.id" className="crwd-font text-primary no-underline">
                        drop<b>id</b>™
                      </a>
                      . If you don't have a{' '}
                      <a href="https://crwd.id" className="crwd-font text-primary no-underline">
                        drop<b>id</b>™
                      </a>
                      , you can set one up later.
                    </small>
                    {errors.email?.message && (
                      <small id="email-help" className="p-error block text-left mt-2 mb-2">
                        {errors.email.message}
                      </small>
                    )}
                  </div>
                  <div className="w-full">
                    <Controller
                      name="mobile"
                      defaultValue=""
                      control={control as unknown as Control<FieldValues, any>}
                      render={({ field: mobileField }) => {
                        const { value, onChange, ...rest } = mobileField;
                        const [, number] = value.split('|');

                        const onChangeWithCountryCode = (value: string, countryCode: string) => {
                          onChange(`${countryCode}|${value}`);
                        };
                        return (
                          <>
                            <PhoneInput
                              className={errors.mobile?.message ? 'p-invalid' : ''}
                              inputProps={rest}
                              onChange={onChangeWithCountryCode}
                              value={number}
                            />
                            {errors.mobile?.message && (
                              <small id="username2-help" className="p-error block text-left mt-2 mb-2">
                                {errors.mobile.message}
                              </small>
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="text-right w-full">
                    <Button label="VISIT SEC FILED SITE" className="light" type="submit" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
};

export default RegisterForm;
