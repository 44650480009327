import { Container } from 'components/container';
import { Section } from './index.styled';
import parse from 'html-react-parser';
import data from './data.json';
import { SectionHeader } from 'components/Section';

const Vision = ({ light = false }) => {
  return (
    <Section className={light && 'light'}>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2">
            <SectionHeader className="text-center" label={data.label} title={data.title} />
            <div className="text-center mt-6">{parse(data.text)}</div>
          </div>
        </div>
      </Container>
      <img src="assets/images/dropverse_ship.jpeg" className="w-full font-size: 18px;" alt="" />
    </Section>
  );
};

export default Vision;
