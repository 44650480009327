import React, { useState } from 'react';
import { Slider } from 'primereact/slider';
import { InputNumber } from 'primereact/inputnumber';

const TokenCalculator = () => {
  const [dollarAmount, setDollarAmount] = useState(5000);
  const [startUnitAmount, setStartUnitAmount] = useState(5000.0);
  const [calculateUnitAmount, setCalculateUnitAmount] = useState(5000.0);

  const numberFormat = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  });

  const moneyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const totalUnits = dollarAmount / startUnitAmount;

  const calculateAmount = (originalInvestment: number, startUnitAmount: number, unitAmount: number) => {
    return moneyFormat.format((originalInvestment / startUnitAmount) * unitAmount);
  };

  return (
    <div style={{ marginBottom: '4rem' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '2rem',
          width: '100%',
        }}
      >
        <h1>
          <span
            className="crwd-font"
            style={{
              fontSize: '40px',
            }}
          >
            drop<b>units</b>™
          </span>
          <span className='ml-2'>Wallet Estimator</span>
        </h1>
        <p className="text-justify">
          This tool is designed for you to use for your own purpose. On DROP Protocol™ we hope that you pay attention to the fundamental value of our offering when using this tool. We have removed future pricing to remain compliant and ask that you don’t focus purely on price movements.
        </p>
        <h1>Reserve Units At</h1>
        <div className="p-inputgroup w-10rem">
          <span className="p-inputgroup-addon">$</span>
          <InputNumber
            value={startUnitAmount}
            onChange={e => setStartUnitAmount(e.value)}
            placeholder="Unit Amount"
            minFractionDigits={2}
            maxFractionDigits={3}
          />
        </div>
        <h1 className="mb-6">Per Unit</h1>
        <h1 className="mb-1">Total Reserve Amount</h1>
        <div className="flex flex-row">
          <div className="p-inputgroup" style={{ marginRight: '1rem' }}>
            <span className="p-inputgroup-addon">$</span>
            <InputNumber
              value={dollarAmount}
              min={5000}
              max={5000000}
              step={5000}
              onChange={e => setDollarAmount(e.value)}
              placeholder="Amount"
              showButtons
            />
          </div>
          <div
            style={{
              marginLeft: '1rem',
              flexWrap: 'nowrap',
              color: 'var(--primary-color)',
              display: 'flex',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              border: '1px solid var(--primary-color)',
              padding: '1rem',
              borderRadius: '5px',
            }}
          >
            {`${numberFormat.format(totalUnits)} Units`}
          </div>
        </div>
        <Slider
          style={{ width: '24rem', marginTop: '1rem', marginBottom: '4rem' }}
          min={5000}
          max={5000000}
          step={5000}
          value={dollarAmount}
          onChange={e => setDollarAmount(e.value as number)}
        />
        <h1 className="mb-1">Calculate Value</h1>
        <h1>{numberFormat.format(totalUnits)} X </h1>
        <div className="p-inputgroup w-10rem">
          <span className="p-inputgroup-addon">$</span>
          <InputNumber
            value={calculateUnitAmount}
            onChange={e => setCalculateUnitAmount(e.value)}
            placeholder="Unit Amount"
            minFractionDigits={2}
            maxFractionDigits={3}
          />
        </div>{' '}
        <h1>= {calculateAmount(dollarAmount, startUnitAmount, calculateUnitAmount)}</h1>
      </div>
    </div>
  );
};

export default TokenCalculator;
