import { Container } from 'components/container';
import data from './data.json';
import { Section } from './index.styled';
import parse from 'html-react-parser';
import { SectionHeader } from 'components/Section';

const Process = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 text-center">
            <SectionHeader
              label="Welcome"
              title="Our self-certification process"
              text="On this page participants will determine their accredited status."
            />
          </div>
        </div>

        <div className="wrap">
          {data.map((item, i) => (
            <div key={i} className="mt-7">
              <h2>{parse(item.label)}</h2>
              <>{parse(item.text)}</>
              <div className="grid mt-2">
                {item.items.map((v, k) => (
                  <div key={i + k} className="col-12 md:col-6 flex">
                    <div className={`item`}>{parse(v)}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Process;
