import styled from 'styled-components';

interface Props {
  right?: boolean;
  bottom?: boolean;
}
export const Divider = styled.div<Props>`
  position: absolute;
  height: 1px;
  width: 65%;
  left: ${({ right = false }) => (right ? 'unset' : '0')};
  right: ${({ right = false }) => (right ? '0' : 'unset')};
  top: ${({ bottom = false }) => (bottom ? 'unset' : '0')};
  bottom: ${({ bottom = false }) => (bottom ? '0' : 'unset')};
  // background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
  background: linear-gradient(to right, #dfdfdf, #f4f4f4, #00cfe5);

  @media (max-width: 576px) {
  }
`;
