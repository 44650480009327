import { Container } from 'components/container';
import { Section } from './index.styled';
import { Image } from 'primereact/image';
import { SectionHeader2 } from 'components/Section';

const BigData = () => {
  return (
    <Section>
      <Container>
        <div className="grid relative">
          <div className="col-12 md:col-4">
            <Image src="/assets/images/planet_b.png" width="100%" />
          </div>
          <div className="col-12 md:col-8" style={{zIndex: 2}}>
            <SectionHeader2
              className="mb-7"
              label=""
              title="Interconnected galactic story telling where you Learn to Earn™. <br/><br/> To colonize through prosperity by empowering people to gain knowledge and compel others to: "
              text="<span>
              - Join them.<br>
              - Unlock new dicoveries.<br>
              - Share in their exploration.</span>"
            />
          </div>
        </div>
        <div className="states grid">
          <div className="col-12 md:col-3">
            <div className="grid align-items-center">
              <h3>11</h3>
              <p>
                ACROSS
                <br /> GALACTIC COMMERCE SECTORS
              </p>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="grid align-items-center">
              <h3>158</h3>
              <p>
                ASSET BACKED SECURITY TOKENS <br /> USED AS INTERSTELLAR UTILITY OF WORTH{' '}
              </p>
            </div>
          </div>
          <div className="col-12 md:col-5 mail-us">
            <a href="mailto:info@dropprotocol.io?subject=dropunits">
              <div className="grid">
                <div className="text valign">
                  <div className="full-width">
                    <p>WANT TO LEARN MORE?</p>
                    <h6>info@dropprotocol.io</h6>
                  </div>
                </div>
                <div className="mail-icon">
                  <div className="icon-box">
                    <span className="icon pe-7s-mail"></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default BigData;
