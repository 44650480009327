import { Container } from 'components/container';
import { Section } from './index.styled';
import { ProgressBar } from 'primereact/progressbar';
import parse from 'html-react-parser';

const Fund = ({ title, totalRound, raisedAmount, investorCounts }) => {
  const moneyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const funded = raisedAmount ? Math.ceil((raisedAmount / totalRound) * 100) : 0;

  return (
    <Section bg="/assets/images/pattern.png">
      <Container>
        <div className="grid">
          <h2 className="text-center w-full">{parse(title)}</h2>
          <div className="col-12 md:col-10 md:col-offset-1">
            <div className="total">{moneyFormat.format(totalRound)}</div>
            <ProgressBar value={funded}></ProgressBar>
            <div className="goal-stat">
              <span className="goal-number">{funded}%</span>
              <span className="goal-label">TO GOAL</span>
            </div>
            <div className="goal-stat">
              <span className="goal-number">{moneyFormat.format(raisedAmount)}</span>
              <span className="goal-label">RAISED</span>
            </div>
            <div className="goal-stat">
              <span className="goal-number">{investorCounts}</span>
              <span className="goal-label">INVESTORS</span>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Fund;
