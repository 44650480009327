import { Container } from 'components/container';
import { Section } from './index.styled';
import { SectionHeader } from 'components/Section';

const BusinessModel = () => {
  return (
    <Section>
      <Container>
        <div className="grid px-4 md:px-0">
          <div className="col-12 xl:col-7">
            <SectionHeader
              label="OUR VISION"
              title="An intergalactic database powers Decentralized Discovery"
              text="DROP, powered by our AI partner’s proprietary decentralized cloud, curates, targets, and connects commerce to capital with enriched data in real-time to benefit all participants in our ecosystem."
            />
            <div className="grid details">
              <div className="col-12 md:col-6">
                <h4>DROP Knowledge Quest</h4>
                <p className='w-10 ml-0'>
                  Our Keys mobilize and matching members with reward opportunities through our Learn to Earn™ platform
                </p>
              </div>
              <div className="col-12 md:col-6">
                <h4>Access</h4>
                <p className='w-10 ml-0'>Helping members realize their intergalactic goals by matching them with knowledge driven quests.</p>
              </div>
            </div>
          </div>
          <div className="col-12 xl:col-5">
            <div className="circle-container">
              <div className="circle">
                <h4>1.5 billion+</h4>
                <p>
                  verified individual
                  <br /> identities
                </p>
              </div>
              <div className="circle">
                <h4>20 million</h4>
                <p>
                  new identities <br />
                  added each month
                </p>
              </div>
              <div className="circle">
                <h4>2500+</h4>
                <p>
                  Data attributes <br />
                  per identity
                </p>
              </div>
              <div className="circle">
                <h4>158</h4>
                <p>
                  subindustries <br />
                  in GICS
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default BusinessModel;
