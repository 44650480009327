import { Container } from 'components/container';
import instructionsData from './wire-instructions.json';
import { Section } from './index.styled';
import { SectionHeader } from 'components/Section';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useForm } from 'react-hook-form';
import Input from 'components/input';

const Instructions = () => {
  const { control } = useForm();

  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 text-center">
            <SectionHeader label="INSTRUCTIONS" title="PERSONAL INFORMATION and WIRE INSTRUCTIONS" text="" />
          </div>
        </div>

        <Accordion className="mt-6">
          <AccordionTab
            header={
              <div>
                <i className="pi pi-users mr-2"></i>
                <span>PERSONAL INFORMATION</span>
              </div>
            }
          >
            <div className="grid mt-5">
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="First Name" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="Middle Name" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="Last Name" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="Email" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="Date of birth" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="Mobile number" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="Nationality" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="Street address" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="City" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="State/Province" />
              </div>
              <div className="col-12 md:col-6">
                <Input className="" control={control} defaultValue="" name="first_name" label="Country" />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab
            header={
              <div>
                <i className="pi pi-users mr-2"></i>
                <span>WIRE INSTRUCTIONS</span>
              </div>
            }
          >
            <div className="grid mt-5 wire-ins">
              {instructionsData.map((instruction: any) => (
                <div className="col-12 md:col-12">
                  <SectionHeader label={instruction.heading} title={instruction.title} text="" />

                  {instruction.items.map(item => (
                    <>
                      <h4>{item.label}</h4>
                      <p>{item.value}</p>
                      <br />
                    </>
                  ))}
                </div>
              ))}
            </div>
          </AccordionTab>
        </Accordion>
      </Container>
    </Section>
  );
};

export default Instructions;
