import { Container } from 'components/container';
// import { Avatar } from 'primereact/avatar';
import { Section } from './index.styled';
// import data from './data.json';
import { GradiantText } from 'components/text';

const MeetTeam = () => {
  return (
    <Section>
      <Container>
        <GradiantText>MEET THE TEAM</GradiantText>
        <h4 className="subtitle mt-4 mb-5">OUR MOST VALUABLE ASSETS</h4>
        <h2 className="title mb-5">
          An elite team of successful, proven, and experienced engineers, technology entrepreneurs, financiers &
          business executives
        </h2>
      </Container>
      <div className="box">
        <Container>
          <div className="grid">
            <div className="col-12 md:col-7">
              <div className="inner-box">
                <div className="grid my-5">
                  <img src="/assets/images/drop/brent_space.jpg" alt="Team" className="w-full" />
                  {/* {data.map((m, i) => (
                    <div key={i} className="col-12 md:col-4">
                      <div className="grid card">
                        <div className="col-4">
                          <Avatar image={m.img} shape="circle" size="xlarge" />
                        </div>
                        <div className="col-8">
                          <h5>{m.name}</h5>
                          <p className="des">{m.detail}</p>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-5 pl-5">
              <p className="des">KEY STRATEGIC ADVISORS</p>
              <ul>
                <li>To be Announced</li>
              </ul>
              <p className="des">
                The DROP Protocol™ advisory board members bring a wealth of expertise and knowledge to the organization
                with a combined experience in access to capital, executive leadership, and global business. While their
                identities remain confidential, their remarkable backgrounds contribute significantly to the strategic
                direction and decision-making processes within the organization.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </Section>
  );
};

export default MeetTeam;
