import PreLoading from 'components/pre-loading';
import Banner from '../../components/banner';
// import Content from './content';
import bannerData from './data/bannerData.json';
import PrivacyData from './data/privacy_policy_drop.json';

const PrivacyPage = () => {
  return (
    <>
      <PreLoading label={PrivacyData.title} />
      <Banner {...bannerData} />
      <div className="grid m-10">
        <div className="col-12 lg:col-8 lg:col-offset-2 py-6">
          <h1 className="text-center m-6">Privacy Policy</h1>
          {PrivacyData.paragraphs.map((paragraph, index) => (
            <div key={index} className="paragraph px-4">
              {paragraph.header && <h2 className="header">{paragraph.header}</h2>}
              {paragraph.content && <p className="content">{paragraph.content}</p>}
              {paragraph.bullets && (
                <ul className="bullets">
                  {paragraph.bullets.map((bullet, bulletIndex) => (
                    <li key={bulletIndex}>{bullet}</li>
                  ))}
                </ul>
              )}
              {paragraph.numbers && (
                <ol className="numbers">
                  {paragraph.numbers.map((number, numberIndex) => (
                    <li key={numberIndex}>{number}</li>
                  ))}
                </ol>
              )}
            </div>
          ))}
        </div>
      </div>
      {/* <Content /> */}
    </>
  );
};
export default PrivacyPage;
