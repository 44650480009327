import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  // background: var(--surface-card);
  padding: 80px 0;

  .heading {
    p {
      color: var(--text-color);
      font-size: 30px;
      font-weight: 200;
      line-height: 1.3;
    }
  }

  .details {
    margin-top: 50px;

    h4 {
      font-size: 22px;
      font-weight: 500;
    }

    p {
      font-weight: 200;
      margin: 0 auto;
      text-align: justify;
    }
  }

  .circle-container {
    position: relative;
    left: 0;
    width: 320px;
    height: 320px;
    margin: 0 auto;
    @media screen and (min-width: 540px) {
      transform: scale(0.75);
      width: 500px;
      height: 500px;
    }
    @media screen and (min-width: 960px) {
      transform: scale(1);
    }

    .circle {
      position: absolute;
      bottom: 0;
      padding: 10px;

      h4 {
        text-align: center;
        font-size: 20px;
        @media screen and (min-width: 540px) {
          font-size: 25px;
        }
        font-weight: 400;
        font-family: monospace;
      }

      p {
        text-align: center;
        font-size: 12px;
        @media screen and (min-width: 540px) {
          font-size: 16px;
        }
        font-weight: 100;
        font-family: monospace;
        margin: 0 auto;
        width: 70%;
        line-height: 1;
      }

      &:nth-child(1) {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: hsla(191, 86%, 49%, 0.1);
      }

      &:nth-child(2) {
        left: 10%;
        width: 80%;
        height: 80%;
        border-radius: 100%;
        background: hsla(191, 86%, 49%, 0.2);
      }

      &:nth-child(3) {
        left: 20%;
        width: 60%;
        height: 60%;
        border-radius: 100%;
        background: hsla(191, 86%, 49%, 0.3);
      }

      &:nth-child(4) {
        left: 30%;
        width: 40%;
        height: 40%;
        border-radius: 100%;
        background: linear-gradient(
          190deg,
          rgba(0, 207, 229, 0.42620798319327735) 0%,
          rgba(246, 79, 89, 0.5606617647058824) 100%
        );
      }
    }
  }
`;
