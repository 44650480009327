import styled from 'styled-components';

export const Section = styled.section`
  padding-top: 100px;
  padding-bottom: 0;
  background: var(--surface-card);

  .title {
    font-size: 55px;
    line-height: 65px;
    margin: 10px 0px !important;
    font-weight: 600 !important;
  }

  .bottom-grid {
    margin-top: 50px;

    > div {
      align-self: stretch;
      display: flex;
    }
  }
  .asset-class {
    align-items: end;
    p {
      text-align: justify;
    }
    .asset-info {
      div {
        position: relative;
        background: rgba(255, 255, 255, 0.04);
        padding: 15px 0;
        margin-bottom: 25px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
        margin: 0 auto;
        display: flex;
      }
      p {
        padding: 0 1rem;
      }
    }
  }
  .crwdworld-section p {
    padding: 0 0 0 35px;
  }

  @media (max-width: 576px) {
  }
`;

export const ContractWrap = styled.div`
  position: relative;
  background: transparent;
  padding: 15px 0;
  margin-bottom: 25px;

  .p-button-link {
    margin-bottom: -12px;
    @media (min-width: 960px) {
      margin-bottom: initial;
    }
  }
  .inner-box {
    padding: 0 15px 0 15px;
    @media (min-width: 960px) {
      padding: 0 15px 0 85px;
    }

    * {
      z-index: 0;
    }
  }
  .contract-title {
    font-size: 18px;
    @media (min-width: 960px) {
      font-size: 24px;
    }
    margin-bottom: -10px;
    padding-bottom: 0px;
    color: #fff;
    text-transform: lowercase;
  }
  .contract-subtitle {
    font-size: 13px;
  }
  .icon-img-box {
    margin-left: 30px;
    position: relative;
    left: unset;
    top: unset;
    transform: unset;
    @media (min-width: 960px) {
      position: absolute;
      left: 0px;
      top: 54%;
      transform: translateY(-50%);
      margin-left: unset;
    }

    img {
      height: 80px;
      width: 80px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 95%;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: 15px;
    z-index: 0;
    background: rgba(255, 255, 255, 0.04);
  }

  .p-button-link {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: left;
    display: block;
  }

  p {
    font-size: 15px;
    margin: 10px 0;
    text-align: justify;

    a {
      color: var(--primary-color);
    }
  }

  h4 {
    line-height: 1;
    margin-top: 30px;
    text-align: justify;
    font-size: 16px;
    margin-left: 37px;
    @media (min-width: 960px) {
      margin-left: initial;
    }
  }

  @media (max-width: 576px) {
  }
`;
