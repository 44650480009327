import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  position: relative;
  background: var(--surface-section);
  padding-top: 100px;
  overflow: hidden;

  h2 {
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 30px;
    font-size: 25px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      margin-bottom: 25px;
      align-items: center;
      .post {
        margin-top: 16px;
      }
    }
  }

  .contact {
    ul {
      li {
        i {
          font-size: 30px;
          margin-right: 30px;
        }
        .cont {
          h6 {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            line-height: 2;
            margin: 0;
          }
        }
      }
    }
  }

  .explore {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      span {
        font-size: 40px;
        line-height: 1;
      }
    }
    img.crwd-world {
      width: 88px;
    }
    ul.drop-icons {
      li {
        .img {
          width: 102px;
          margin-right: 15px;
          cursor: pointer;
          overflow: hidden;

          img {
            width: 112px;
            height: auto;
          }
        }
      }
    }
    ul {
      li {
        .img {
          width: 70px;
          margin-right: 15px;
          cursor: pointer;
          overflow: hidden;

          img {
            width: 148px;
            height: auto;
          }
        }

        .post {
          p {
            color: var(--text-color);
            font-size: 22px;
            font-weight: 300;
            line-height: 1;
            margin: 0;
          }

          a {
            font-size: 13px;
            color: var(--primary-color);
            text-decoration: none;
          }
        }

        .subscribe {
          position: relative;
          width: 100%;

          input {
            border-radius: 30px;
            width: 100%;
          }
          button {
            position: absolute;
            top: 0;
            right: -7px;
            padding: 1.571rem;
          }
        }
      }
    }
  }

  .copy-right {
    img {
      margin-bottom: 30px;
      width: 160px;
      margin-left: 1rem;
      @media screen and (min-width: 960px) {
        width: 80px;
      }
      cursor: pointer;
    }

    p {
      color: var(--text-color);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 3px;
      margin-top: 30px;

      span {
        font-size: 18px;
      }

      button {
        font-size: 13px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 3px;
      }
    }
  }

  @media (max-width: 576px) {
  }
  .disclaimer {
    font-size: 12px;
  }
  .privacy {
    margin-top: 40px;
  }
`;
