import PreLoading from 'components/pre-loading';
import Banner from 'components/banner';
import Intro from 'components/intro';
import VideoWrapper from 'components/video-wrapper';
import RegisterForm from 'components/register-form';
import introData from './data/introData.json';
import projectDetailsData from './data/projectDetails.json';
import bannerData from './data/bannerData.json';
import FutureRoadmap from './future';
import WhitepaperDownload from 'components/whitepaper-download';
import CrwdworldSeparator from 'components/crwdworld-separator';
import ContractCard from 'components/contract';
import data from '../about/smart-contract/data.json';
import { Container } from 'components/container';

const RoadmapPage = () => {
  return (
    <>
      <PreLoading label="ROADMAP" />
      <Banner {...bannerData} />
      <FutureRoadmap />
      <CrwdworldSeparator {...projectDetailsData} />
      <Container>
        <div className='grid my-6'>
          {data.slice(1, 5).map((item, key) => (
              <div className="col-12 lg:col-6">
                <ContractCard key={key} {...item} />
              </div>
            ))}
        </div>
      </Container>
      <Intro {...introData} light />
      <WhitepaperDownload />
      <VideoWrapper />
      <RegisterForm light />
    </>
  );
};
export default RoadmapPage;
