import { useEffect, useState } from "react";
import useViewport from "./useViewport";

export function useIsMobile(): boolean {
  const { width } = useViewport();
  const isMobileWidth = width < 380;
  const [isMobile, setIsMobile] = useState(isMobileWidth);

  useEffect(() => {
    if (isMobileWidth !== isMobile) {
      setIsMobile(isMobileWidth);
    }
  }, [width, isMobile, isMobileWidth]);

  return isMobile;
}