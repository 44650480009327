import styled from 'styled-components';

export const Section = styled.section`
  padding: 40px 1rem 100px;
  background: var(--surface-card);
  margin-bottom: 4rem;

  @media screen and (min-width: 960px) {
    padding: 100px 0;
  }

  .title {
    font-size: 55px;
    line-height: 65px;
    margin: 10px 0px !important;
    font-weight: 600 !important;
  }

  .quote {
    margin: 50px 0;

    p {
      font-size: 18px;
      color: var(--primary-color);
    }

    .author {
      p {
        line-height: 1.2;
        font-size: 15px;
      }
    }
  }

  .card {
    background: var(--surface-card);
    padding: 15px;
    text-align: center;

    img {
      height: 80px;
      width: 80px;
    }

    p {
      text-align: justify;
    }
  }

  @media (max-width: 576px) {
  }
`;
