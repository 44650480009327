import Button from 'components/button';
import { Container } from 'components/container';
import SocialLinks from 'components/social';
import { GradiantText } from 'components/text';
import { useNavigate } from 'react-router-dom';
import { FooterWrapper } from './index.styled';
import { Link } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <FooterWrapper>
      <Container className="mb-4">
        <div className="grid px-4">
          <div className="col-12 lg:col-6 xl:col-4 contact mb-4 md:mb-0">
            <h2>Contact us</h2>

            <ul>
              <li>
                <i className="pi pi-map-marker" />
                <div className="cont">
                  <h6>DROP Protocol™ INC.</h6>
                  <p>
                    401 N Carroll Ave.,
                    <br />
                    Ste. 192,
                    <br />
                    Southlake, TX 76092
                  </p>
                </div>
              </li>
              <li>
                <i className="pi pi-envelope" />
                <div className="cont">
                  <h6>Email Us</h6>
                  <p>dropid@dropprotocol.io</p>
                </div>
              </li>
              <li>
                <i className="pi pi-phone" />
                <div className="cont">
                  <h6>Call Us</h6>
                  <p>+1 (855) 953-7537</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-12 lg:col-6 xl:col-4 explore">
            <img
              src="/assets/images/drop_logo.png"
              onClick={() => window.open('https://dropprotocol.io', '_blank')}
              alt="Protocol"
              className="crwd-world"
            />
            <h2>Explore our </h2>

            <ul className="drop-icons">
              <li>
                <div className="img" onClick={() => navigate('/coming-soon/id')}>
                  <img src="/assets/images/drop/drop_id.png" alt="" />
                </div>
                <div className="post">
                  <p className="crwd-font">
                    drop<b>id</b>™
                  </p>
                  <Link to={'/coming-soon/id'}>Visit today!</Link>
                </div>
              </li>
              <li>
                <div className="img" onClick={() => navigate('/coming-soon/market')}>
                  <img src="/assets/images/drop/drop_market.png" alt="crwd market" />
                </div>
                <div className="post">
                  <p className="crwd-font">
                    drop<b>market</b>™
                  </p>
                  <Link to={'/coming-soon/market'}>Visit today!</Link>
                </div>
              </li>
              <li>
                <div className="img" onClick={() => navigate('/coming-soon/capital')}>
                  <img src="/assets/images/drop/drop_capital.png" alt="crwd capital" />
                </div>
                <div className="post">
                  <p className="crwd-font">
                    drop<b>capital</b>™
                  </p>
                  <Link to={'/coming-soon/capital'}>Visit today!</Link>
                </div>
              </li>
              <li>
                <div className="img" onClick={() => navigate('/coming-soon/finance')}>
                  <img src="/assets/images/drop/drop_finance.png" alt="crwd finance" />
                </div>
                <div className="post">
                  <p className="crwd-font">
                    drop<b>finances</b>™
                  </p>
                  <Link to={'/coming-soon/finance'}>Visit today!</Link>
                </div>
              </li>
              {/* <li>
                <div className="subscribe">
                  <InputText name="email" placeholder="Type Your Email" />
                  <Button icon="pi pi-send" className="p-button-rounded" />
                </div>
              </li> */}
            </ul>
          </div>
          <div className="col-12 lg:col-4 copy-right mx-auto text-center lg:text-left lg:m-0 flex flex-column align-items-center lg:align-items-start">
            <SocialLinks
              links={{
                fb: '#',
                twitter: '#',
                instagram: '#',
                youtube: '#',
              }}
            />
            <p>
              © 2023, DROP Protocol™, Inc. All rights reserved <br />
              <span className="crwd-font">
                drop<b>id</b>™
              </span>
              : YOUR PASSPORT TO: THE DROPVerse™
              <br />
              <Button
                label="Privacy Policy"
                className="p-button-link p-0 privacy"
                onClick={() => navigate('/privacy')}
              />
              .
            </p>
          </div>
        </div>
        <div className="disclaimer text-justify">
          <GradiantText>Disclaimer: </GradiantText>
          <p>
            Past performance is not indicative of future performance. Any financial projections or returns shown are
            illustrative examples only, and there can be no assurance that any valuations provided are accurate or in
            agreement with market or industry valuations. We make no representations or warranties as to the accuracy of
            information and accept no liability therefore. There is a potential for loss of part or ALL of the
            investment capital, and each investor should understand that all capital invested may be lost. Investors
            should only consider these investments if they have no need for liquidity and can bear the risk of losing
            their entire investment.
          </p>
          <p>
            Neither the Securities and Exchange Commission nor any federal or state securities commission or any other
            regulatory authority has recommended or approved of the investment or the accuracy or inaccuracy of any of
            the information or materials provided. Private equity investments have significant risks. Investments are
            restricted securities and are subject to restrictions on transfer as described in the offering materials.
            Investment opportunities discussed are “private placements” of securities that are not publicly traded, are
            subject to holding period requirements, and are intended for investors who do not need a liquid investment.
          </p>
          <p>
            This may contain “forward looking statements” which are not guaranteed. All investors should make their own
            determination of whether or not to make any investment, based on their own independent evaluation and
            analysis. It is solely your responsibility to comply with the laws and regulations of your country of
            residence.
          </p>
        </div>
      </Container>
      <iframe
        src="https://portal.theellipsis.exchange/isps/index.html"
        title="ISP Details"
        width="100%"
        height="100%"
        className="block"
        style={{ minHeight: '400px' }}
      />
    </FooterWrapper>
  );
};

export default Footer;
