import styled from 'styled-components';

export const Section = styled.section`
  overflow: hidden;
  padding: 50px 0;

  &.light {
    background: var(--surface-card);
  }

  img {
    width: 88%;
    &.big {
      height: calc(100% - 10px);
      width: 100%;
      margin-top: -10px;
    }
    &.small {
      width: 200px;
    }
  }

  @media (max-width: 576px) {
    .grid {
      gap: 20px;
    }
  }
`;
