import { ParallaxBanner, Section } from './index.styled';

const UniversalAccess = () => {
  return (
    <Section>
      <ParallaxBanner
        layers={[
          {
            image: '/assets/images/drop_logo.png',
            speed: 0,
          },
        ]}
      >
        <div className="caption copy-cap">
          <h1>UNIVERSAL ACCESS</h1>
        </div>
        <div className="caption" onClick={() => window.open('https://crwd.id', '_blank')}>
          <h1 className="stroke">UNIVERSAL ACCESS</h1>
        </div>
      </ParallaxBanner>
    </Section>
  );
};

export default UniversalAccess;
