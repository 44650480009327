import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 100px 0;

  &.light {
    background: var(--surface-card);
  }

  ol {
    li {
      color: var(--text-color);
    }
  }

  @media (max-width: 576px) {
  }
`;
