import styled, { keyframes } from 'styled-components';

const LoadingAnim = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  z-index: 99999999999999;
  background: red;
  transition: all 1s;

  .gif {
    position: fixed;
    left: 50%;
    top: calc(50% - 40px);
    transform: translateX(-50%) translateY(-50%);
    font-weight: 300;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 20px;
    z-index: 9999999999;
  }

  .loading {
    position: fixed;
    left: 50%;
    top: calc(30% - 40px);
    transform: translateX(-50%) translateY(-50%);
    font-weight: 300;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 15px;
    z-index: 9999999999;
    text-align: center;
    span {
      animation: ${LoadingAnim} 2s infinite alternate;

      &:nth-child(1) {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }

      &:nth-child(2) {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
      }

      &:nth-child(3) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }

      &:nth-child(4) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
      }

      &:nth-child(5) {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
      }

      &:nth-child(6) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
      }

      &:nth-child(7) {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
      }
    }
  }

  .progress {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 400px;
    width: 70vw;
    border: 0px;
    height: 1px;
    overflow: hidden;
    pointer-events: none;
    user-select: none;
    z-index: 99999999999999;
    background: rgba(255, 255, 255, 0.05);
    transition: all 1s;

    .progress-bar {
      display: block;
      position: absolute;
      top: 0;
      right: 100%;
      height: 100%;
      width: 100%;
      max-width: 0px;
      transform: translate3d(100%, 0px, 0px);
      background: linear-gradient(to right, #00cfe5, #c471ed, #f64f59);
    }

    &.progress-done {
      width: 100vw;
      max-width: 100vw;

      .progress-bar {
        max-width: 100vw !important;
      }
    }
  }

  &:after,
  &:before {
    content: '';
    position: fixed;
    left: 0;
    height: 50%;
    width: 100%;
    background: black;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &.isdone {
    visibility: hidden;
    transition-delay: 1.5s;

    &:after,
    &:before {
      height: 0;
      transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
      transition-delay: 1s;
    }

    .loading {
      top: 50%;
      opacity: 0;
      transition: all 0.8s;
      transition-delay: 0.5s;
    }

    .progress {
      &.progress-done {
        opacity: 0;
      }
    }
  }
`;
