import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import { useClientContext } from 'contexts/ClientContext';
import { useState } from 'react';
import { GradiantText } from 'components/text';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FocusLock from 'react-focus-lock';

const DialogToken = () => {
  // const { client } = useClientContext();
  // const statusState = useUnitPurchaseStatusQuery(client);
  // const status: any = {}; // statusState.data?.unitPurchaseStatus;
  const [vipToken, setVipToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [tokenError, setTokenError] = useState('');
  const { sdkClient, affiliateCode, setAffiliateCode } = useClientContext();

  const checkToken = async () => {
    setLoading(true);
    const response = await sdkClient.isVipToken({ token: vipToken });
    setLoading(false);
    if (response.isVipToken.isVipToken) {
      setAffiliateCode(vipToken);
    } else {
      setTokenError('Not a valid VIP token');
    }
  };

  return (
    <>
      <Dialog
        breakpoints={{ '960px': '440px', '640px': '90vw' }}
        visible={!affiliateCode}
        closable={false}
        onHide={() => {}}
        draggable={false}
      >
        <FocusLock>
          <div className="flex flex-column gap-3 px-4">
            <GradiantText className="text-center my-0 text-xl">
              Enter your DROP VIP
              <br /> passcode token to continue
            </GradiantText>
            <form className="flex flex-column gap-3"  onSubmit={event => {
                  event.preventDefault();
                  checkToken();
                }}>
              <InputText
                value={vipToken}
                placeholder="DROP VIP passcode token"
                className="text-center font-bold text-lg px-2"
                onChange={event => {
                  event.preventDefault();
                  setVipToken(event.target.value);
                }}
              />
              <Button
                label="Submit token"
                type="submit"
                className="text-center font-bold text-xl"
                loading={loading}
              />
              {!!tokenError && <Message severity="error" text={tokenError} />}
            </form>
          </div>
        </FocusLock>
      </Dialog>
    </>
  );
};
export default DialogToken;
