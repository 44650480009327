const WhitepaperDownload = () => {
  return (
    <div>
      <div className="grid my-8 flex-column-reverse lg:flex-row">
        <div className="col-12 lg:col-8 flex">
          <div className="mx-auto w-10 flex justify-content-center align-content-center flex-column">
            <h3 className="text-lg lg:text-xl opacity-90" style={{ letterSpacing: '1px' }}>
              DOWNLOAD OUR
            </h3>
            <h4 className="text-4xl lg:text-5xl">
              OFFERING MEMORANDUM/
              <br />
              SAFT AGREEMENT
            </h4>
            <p>
              The collapse of weaker business models and the lack of asset backed digital strategies seen in current
              crypto currencies is healthy for the long term growth of the emerging regulated digital asset sector. This
              Offering Memorandum/SAFT Agreement describes the entry of regulated and rule following companies like DROP
              Protocol™.
            </p>
            <p className="mb-6">
              We believe it marks the rise of the next generation of Authentic decentralized intelligence technologies.
              It is a perfect time to invest into asset backed transactions.
            </p>
            <a
              href="https://github.com/CrowdPoint-Technologies/whitepaper/blob/main/DROP%20Regulation%20D506b%20Offering%20Document%20August%2015%2C%202023%20Draft%2022.pdf?raw=true"
              className="whitepaper-pdf-link text-2xl font-bold text-white uppercase no-underline max-w-max px-4 py-2 border-round"
              style={{
                letterSpacing: '0.5px',
                backgroundImage: 'linear-gradient(117deg,var(--primary-color),var(--secondary-color))',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left',
              }}
              target="_blank"
              rel="noreferrer"
            >
              Download now!
            </a>
          </div>
        </div>
        <div className="col-12 lg:col-4 mx-auto  flex justify-content-center align-content-center">
          <a
            href="https://github.com/CrowdPoint-Technologies/whitepaper/blob/main/DROP%20Regulation%20D506b%20Offering%20Document%20August%2015%2C%202023%20Draft%2022.pdf?raw=true"
            target="_blank"
            rel="noreferrer"
            title="White Paper Download"
          >
            <img
              src="/assets/images/drop/whitepaper_download_book.png"
              alt="Whitepaper"
              className="w-full mx-auto flex"
              style={{ maxWidth: '280px' }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default WhitepaperDownload;
