import { Container } from 'components/container';
import { Section } from './index.styled';
import { SectionHeader } from 'components/Section';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import parse from 'html-react-parser';
import data from './data.json';

const Quote = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-6 md:col-offset-3 text-center">
            <SectionHeader label="DROP Protocol | A SHARING ECONOMY PROPELLED BY DECENTRALIZED PROSPERITY" />
          </div>
        </div>
        <p className="text-justify mt-5">
          <span className="crwd-font">
            DROP<b>Verse</b>™
          </span>{' '}
          is a intergalactic community of interacting participants working within a Web 3.0 environment where a complex
          network of knowledge quests work within an interconnected system to benefit you.
        </p>
        <div className="quote">
          <p className="text-right">
            <RiDoubleQuotesL /> In a traditional business ecosystem, the interconnectivity sustains the owner; our
            intergalactic ecosystem is designed to sustain the prosperity for all members.” <br />
            <RiDoubleQuotesR />
          </p>
        </div>
        <div className="grid mt-5 mb-4">
          {data.map((item, i) => (
            <div key={i} className="col-12 md:col-4 flex">
              <div className="card">
                <img src={item.img} alt="" />
                <p className="text-justify">{parse(item.text)}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="grid bottom-grid crwdworld-section">
          <h4 className="text-center w-full">
            WHAT IS{' '}
            <span className="crwd-font">
              DROP<b>Verse</b>™
            </span>
            ?
          </h4>
          <p className="text-justify px-4">
            A little over a year ago, DROPVerse™ was just an idea. Now,{' '}
            <span className="crwd-font">
              DROP<b>Verse</b>™
            </span>{' '}
            is a carefully designed, interconnected galactic system. Every part of the ecosystem was specifically
            curated to energize and unlock the promise of a knowledge economy. Interactivity is the key to making{' '}
            <span className="crwd-font">
              DROP<b>Verse</b>™
            </span>{' '}
            a reality. Our <span className="crwd-font">DROP Unit™’s</span> Digital Interplanetary Supply Crate is the
            underlying digital asset that enables participants to enjoy value from the ecosystem. Read below for more
            information on the ecosystem.
          </p>

          <p className="text-justify px-4">
            <span className="crwd-font">
              DROP<b>Verse</b>™
            </span>{' '}
            is here, it is ready to scale our Knowledge quest and support our current contract obligations. It needs
            you. By joining us today you empower intergalactic participants to Learn to Earn™ and contribute their data
            with the confidence that this Ecosystem will reward all members that choose to participate.
          </p>
        </div>
      </Container>
    </Section>
  );
};

export default Quote;
