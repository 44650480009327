// import { Container } from 'components/container';
// import { openInNewTab } from 'utils/helper';
// import { ParallaxBanner } from './index.styled';
// import { Image } from 'primereact/image';
// import { Button as BTN } from 'primereact/button';

interface Props {
  bg: string;
  title: string;
  label: string;
  className?: string;
  items: Array<{ title: string; labels: Array<{ name: string; link: string }> }>;
}

const Banner = ({ bg, title, className, label, items }: Props) => {
  return <img src={bg} alt="Eco System" className="w-full" />;
};

export default Banner;
