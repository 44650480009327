import styled from 'styled-components';

export const Section = styled.section`
  padding: 40px 0 100px;
  @media screen and (min-width: 960px) {
    padding: 100px 0;
  }
  text-align: center;

  &.light {
    background: var(--surface-card);
  }

  h3 {
    font-weight: 700;
    line-height: 1.4;
    font-size: 30px;
  }
  button {
    width: 100%;
    font-size: 18px;
    justify-content: center;
  }
`;
