import styled from 'styled-components';

export const Section = styled.section`
  padding: 60px 0;
  @media screen and (min-width: 960px) {
    padding: 120px 0;
  }

  &.light {
    background: var(--surface-card);
  }

  h3 {
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 1.4;
    padding-right: 0px;
    font-size: 28px;

    @media screen and (min-width: 540px) {
      font-size: 36px;
    }
    @media screen and (min-width: 960px) {
      font-size: 30px;
      padding-right: 20px;
    }

    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: var(--primary-color);
    }
  }

  h4 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 30px;
    color: #fff;
  }

  // .flip-clock {
  //   transform: scale(0.4);
  //   @media screen and (min-width: 320px) {
  //     transform: scale(0.5);
  //   }
  //   @media screen and (min-width: 960px) {
  //     transform: initial;
  //   }
  //   font-family: var(--font-family);
  //   --fcc-flip-duration: 0.7s; /* transition duration when flip card */
  //   --fcc-digit-block-width: 60px; /* digit card's width */
  //   --fcc-digit-block-height: 90px; /* digit card's height */
  //   --fcc-digit-font-size: 70px; /* font size of digit */
  //   --fcc-digit-color: white; /* color of digit */
  //   --fcc-background: var(--surface-section); /* digit card's background */
  //   --fcc-label-color: #ffffff; /* time label's color */
  //   --fcc-divider-color: var(--surface-overlay); /* divider's color */
  //   justify-content: center;

  //   ._2EBbg {
  //     font-weight: 700;
  //   }
  // }
`;
