import styled from 'styled-components';

export const PhoneInputWrapper = styled.div`
  .button-container {
    background-color: var(--primary-color) !important;
    .selected-flag {
      background: var(--primary-color) !important;
    }
    .country-list {
      .country:hover {
        background: var(--primary-color);
      }
      .search-container:hover {
        background: none;
      }

      :not(:first-child):hover {
        background: none;
      }

      .active,
      .highlight {
        background: var(--primary-color) !important;
      }

      .country {
        .dial-code {
          color: var(--text-color) !important;
        }
      }
    }
  }

  .search-container {
    display: flex;
    align-items: center;
    background: var(--surface-card) !important;
    border: 1px solid var(--surface-border) !important;
  }
`;
