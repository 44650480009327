import { Container } from 'components/container';
import { Section } from './index.styled';
import data from './data.json';
import ContractCard from 'components/contract';

const TokenAssets = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12">{/* <SectionHeader className="mb-5" /> */}</div>
        </div>

        <div className="grid">
          {data.slice(0, 2).map((item, key) => (
            <div className="col-12 md:col-6 flex">
              <ContractCard key={key} {...item} />
            </div>
          ))}
        </div>
      </Container>

      <img src="/assets/images/drop_banner.jpg" alt="Vogan" className="w-full my-7 block" />

      <Container>
        <div className="grid">
          {data.slice(2, 4).map((item, key) => (
            <div className="col-12 md:col-6 flex">
              <ContractCard key={key} {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default TokenAssets;
