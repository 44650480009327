import PreLoading from 'components/pre-loading';
import Process from './process';
import bannerData from './data/bannerData.json';
import Banner from 'components/banner';
import Invester from './invester';
import Definations from './definations';
import Instructions from './instructions';

const DisclosurePage = () => {
  return (
    <>
      <PreLoading label="DISCLOSURE" />
      <Banner {...bannerData} />
      <Process />
      <Invester />
      <Instructions />
      <Definations />
    </>
  );
};
export default DisclosurePage;
