import { Container } from 'components/container';
import { GradiantText } from 'components/text';
import { Section } from './index.styled';
import FlipClockCounter from 'components/flip-clock-counter';

const CountUP = ({ light = false }) => {
  let date = new Date('2023/08/12 22:00:00');
  var CSToffSet = -360; //CST is -6:00 of UTC; i.e. 60*6 = -360 in minutes
  let offset = CSToffSet * 60 * 1000;
  var CSTTime = new Date(date.getTime() + offset);

  return (
    <Section className={light && 'light'}>
      <Container fluid>
        <FlipClockCounter time={CSTTime.getTime()} type="down">
          Finished
        </FlipClockCounter>
        <div className="grid m-0">
          <div className="col-12 lg:col-8 lg:col-offset-2 p-0 text-center">
            <h4 className="mt-4">WELCOME TO A BETTER VERSION OF THE 4TH INDUSTRIAL REVOLUTION</h4>
            <GradiantText className="text-justify">
              <br />
              <br />
              <h4>
                Our assembly of new worlds require fuel to keep it going. A fuel is any compound which has stored
                energy. Our DROP Protocol™ is powered by a the most powerful fuel harnessed by the universe: The Human
                Experience.
              </h4>
              <h4 className="text-center">KNOWLEDGE IS THE QUANTUM COMPUTE RESOURCE</h4>
              <h4>
                Our journey across the cosmos begins when you become a member of the DROP Community™. It is DROP’s goal
                to educate our Members with our Learn to Earn™ platform, further enhancing each of our members’
                experience. Whether you are novice or technologically advanced, there will be experiences that will be
                tailored to your knowledge and ability.
              </h4>
            </GradiantText>
            <p className="text-justify">
              After you visit this site we are confident you will understand the role our DROPunit™ plays in helping
              you. It is our desire that you understand just how important you are to the world today and the value you
              bring to the world of interconnected computing.
            </p>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default CountUP;
