const SaftAgreement = () => {
  return (
    <div>
      <div className="grid my-8 flex-column-reverse lg:flex-row">
        <div className="col-12 lg:col-8">
          <div className="mx-auto w-10 flex justify-content-center align-content-center flex-column">
            <h3 className="text-lg lg:text-xl opacity-90" style={{ letterSpacing: '1px' }}>
              SAFT
            </h3>
            <h4 className="text-4xl lg:text-5xl">Simple Agreement for Future Tokens</h4>
            <p className="mb-6">
              In Round 1, our SAFT is a security, and can be sold pursuant to exemptions (i.e. “accredited investors”)
              available under unregistered securities offering that follows a Regulation D 506c framework.
            </p>
          </div>
        </div>
        <div className="col-12 lg:col-4 mx-auto  flex justify-content-center align-content-center">
          <img
            src="/assets/images/unit/safet_billet-icon.png"
            alt="Whitepaper"
            className="w-full mx-auto flex"
            style={{ maxWidth: '280px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default SaftAgreement;
