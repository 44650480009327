import { ButtonProps } from 'primereact/button';
import { BTN } from './index.styled';
import parse from 'html-react-parser';

const Button = (props: ButtonProps) => {
  let { label, ...rest } = props;
  return <BTN {...rest}>{parse(label)}</BTN>;
};

export default Button;
