const VogonDivider = () => {
  return (
    <div
      className="block py-2"
      style={{
        background: 'url(/assets/images/blockchain/space-bg.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="grid my-8 flex-column lg:flex-row">
        <div className="col-12 lg:col-9 flex">
          <div className="mx-auto w-10 flex justify-content-center align-content-center flex-column">
            <h3 className="text-lg lg:text-xl opacity-90" style={{ letterSpacing: '1px' }}>
              Douglas Adams, Hitchhiker's Guide to the Galaxy
            </h3>
            <h4 className="text-4xl lg:text-5xl">
              "Vogons are described as officiously bureaucratic, a line of work at which they perform so well that the
              entire galactic bureaucracy is run by them."
            </h4>
          </div>
        </div>
        <div className="col-12 lg:col-3 mx-auto flex justify-content-center align-content-center">
          <img
            src="/assets/images/blockchain/astronaut.png"
            alt="Douglas Adams Quote"
            className="w-full mx-auto flex"
            style={{ maxWidth: '260px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default VogonDivider;
