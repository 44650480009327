import styled from 'styled-components';

export const Section = styled.section`
  padding: 60px 0;
  @media screen and (min-width: 960px) {
    padding: 120px 0;
  }
  background: var(--surface-card);

  @media (max-width: 576px) {
  }
`;

export const ItemWrap = styled.div`
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease-in-out;
  display: block;
  top: 0;
  background-size: cover;
  border: none;
  padding: 40px 20px;
  text-align: center;
  max-width: 380px;
  margin: 0 auto;
  height: 390px;
  &:hover {
    top: -3px;

    .service_icon {
      background: transparent;

      img {
        filter: none;
      }
    }
  }
  a {
    color: var(---primary-color-text);
  }

  .service_icon {
    display: inline-block;
    position: relative;
    width: 140px;
    height: 140px;
    padding: 30px;
    margin-bottom: 30px;
    border: 2px solid #00dcd8;
    border-radius: 50%;
    // background-image: linear-gradient(90deg, #c200db 0, #e80074);
    background-image: linear-gradient(90deg, #dfdfdf, #f4f4f4, #00cfe5);
    transition: all 0.3s ease-in-out;

    img {
      max-width: 100%;
      height: auto;
      // filter: brightness(0) invert(1);
    }

    .step-num {
      position: absolute;
      top: 0;
      right: -10px;
      background: #21d397;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      line-height: 40px;
      color: #fff;
    }
  }

  h6 {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 500;
    .crwd-font {
      line-height: 1;
      font-size: 30px;
    }
  }

  p {
    line-height: 1.9;
    margin: 0;
    font-size: 15px;
    text-align: justify;
  }
  @media (max-width: 576px) {
  }
`;
