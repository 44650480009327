import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  text-align: center;
  font-size: 32px;
  height: 220px;
  justify-content: center;
  display: flex;
  h2 {
    font-size: 58px;
    color: #9febed;
    border-top: 15px solid #9febed;
    text-transform: uppercase;
  }
`;
export const ComingSoonWrapper = styled.div`
  .coming-soon-banner {
    height: 100vh;
    &:after {
      opacity: 0.2;
    }
    > div:first-child {
      background-position: top !important;
    }
  }
`;
