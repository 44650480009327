import { Container } from 'components/container';
import { Section } from './index.styled';
import { SectionHeader } from 'components/Section';

const BusinessModel = () => {
  return (
    <Section>
      <Container>
        <div className="grid px-4 md:px-0">
          <div className="col-12 xl:col-7">
            <SectionHeader
              label="BUSINESS MODEL"
              title="Learn to Earn™"
              text="DROP Protocol™, powered by a proprietary Authentic Intelligence decentralized cloud, curates, targets, and connects enriched data in real-time to benefit all participants."
            />
            <div className="grid details">
              <div className="col-12 md:col-6">
                <h4>Decentralized Discovery</h4>
                <p>
                  Mobilizing and matching high propensity, targeted customers with vendors for Learn to Earn™
                  opportunities.
                </p>
              </div>
              <div className="col-12 md:col-6">
                <h4>Authentic Intelligence</h4>
                <p>
                  Our members can problem solve challenges and earn keys and contribute their human experience to
                  enhance our authentic intelligence.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 xl:col-5">
            <div className="circle-container">
              <div className="circle">
                <h4>1.5 billion+</h4>
                <p>
                  verified individual
                  <br /> identities
                </p>
              </div>
              <div className="circle">
                <h4>20 million</h4>
                <p>
                  new identities <br />
                  added each month
                </p>
              </div>
              <div className="circle">
                <h4>2500+</h4>
                <p>
                  Data attributes <br />
                  per identity
                </p>
              </div>
              <div className="circle">
                <h4>158</h4>
                <p>
                  subindustries <br />
                  in GICS
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default BusinessModel;
