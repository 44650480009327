import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  // background: var(--surface-card);

  .heading {
    p {
      text-align: center !important;
    }
  }

  p {
    span {
      font-size: 25px;
      color: var(--primary-color);
    }
  }

  @media (max-width: 576px) {
  }
`;
