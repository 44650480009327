import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  // background: var(--surface-card);

  .item {
    position: relative;
    padding: 30px 25px 15px;
    width: 100%;
    background: var(--primary-color-lighter3);

    label {
      line-height: 1.5;
      font-weight: 400;
      font-size: 16px;
      margin-left: 30px;
      color: var(--secondary-color);
      text-align: justify;
    }
  }

  @media (max-width: 576px) {
  }
`;
