import { Section } from './index.styled';
import { Container } from 'components/container';

const ProjectDetails = ({ topImg = '', bottomImg = '', details = [], light = false }) => {

  return (
    <>
      {details.length > 0 && (
        <Section className={light && 'light' }>
            <Container fluid>
              <div className="project-details grid mb-2 md:mb-8 pb-8">
                {details &&
                  details.map((detl, k) => (
                    <div className="col-12 sm:col-6 lg:col-3" key={k}>
                      <img src={detl} alt={`Round ${k + 1}`} />
                    </div>
                  ))}
              </div>
            </Container>
        </Section>
      )}
    </>
  );
};

export default ProjectDetails;
