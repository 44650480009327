import { Container } from 'components/container';
import { Section } from './index.styled';
import data from './data.json';
import { SectionHeader } from 'components/Section';

const Towels = ({ light = false }) => {
  return (
    <Section className={light && 'light'}>
      <Container>
        <div className="grid align-items-center">
          <div className="col-12 md:col-6">
            <img src="assets/images/Dropverse_start.png" className="w-full" alt="" />
          </div>
          <div className="col-12 md:col-6">
            <SectionHeader className="text-center" label={data.label} title={data.title} text={data.text} />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Towels;
