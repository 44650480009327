import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

yup.setLocale({
  mixed: {
    required: (data: { path: string }) => {
      if (!!data?.path) {
        return `${data.path
          .split('_')
          .map(name => name.charAt(0).toUpperCase() + name.slice(1))
          .join(' ')} is a required value*`;
      }
      return 'Required value*';
    },
  },
  string: {
    email: () => {
      return 'Invalid email format*';
    },
  },
});

const RegisterFormSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().required().email(),
  mobile: yup
    .string()
    .required()
    .test('is-valid-phone-number', 'Invalid phone number*', value => {
      if (!value) return false;
      const [, number] = value.split('|')
      return isValidPhoneNumber(`+${number}`);
    }),
});

export const RegisterFormResolver = yupResolver(RegisterFormSchema);
