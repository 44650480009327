import PreLoading from 'components/pre-loading';
import { ComingSoonWrapper, Section } from './index.styled';
import bannerData from './bannerData.json';
import { useParams } from 'react-router-dom';
import Banner from 'components/banner';

export const ComingSoon = () => {
  const { value } = useParams();
  bannerData.bg = `/assets/images/drop/coming-soon/drop_${value}_coming.jpg`;
  debugger;
  return (
    <ComingSoonWrapper>
      <PreLoading label={value} />
      <Banner {...bannerData} className="coming-soon-banner" />
      <Section>
        <h2>Currently Under Development</h2>
      </Section>
    </ComingSoonWrapper>
  );
};
