import { Container } from 'components/container';
import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import data from './data.json';
import { Section } from './index.styled';
import { SectionHeader } from 'components/Section';

const Invester = () => {
  const [selectedStatments, setSelectedStatments] = useState<any>([]);

  const onCategoryChange = (e: { value: any; checked: boolean }) => {
    let _selectedStatments = [...selectedStatments];

    if (e.checked) {
      _selectedStatments.push(e.value);
    } else {
      for (let i = 0; i < _selectedStatments.length; i++) {
        const stmt = _selectedStatments[i];

        if (stmt.value === e.value.value) {
          _selectedStatments.splice(i, 1);
          break;
        }
      }
    }
    setSelectedStatments(_selectedStatments);
  };

  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 text-center">
            <SectionHeader
              label="INVESTOR"
              title="ACCREDITED INVESTOR SELF-CERTIFICATION"
              text="<p className='text-justify'> The Applicant hereby certifies to DROP Protocol™ Inc their accreditation status as defined in Rule 501 of Regulation D promulgated under the Securities Act of 1933, because (check appropriate statement(s)):</p>"
            />
          </div>
        </div>

        <div className="wrap">
          {data.map(statement => {
            return (
              <div className="item mt-2">
                <div key={statement.value} className="field-checkbox">
                  <Checkbox
                    inputId={statement.value}
                    name="statement"
                    value={statement}
                    onChange={onCategoryChange}
                    checked={selectedStatments.some(item => item.value === statement.value)}
                  />
                  <label htmlFor={statement.value}>{statement.text}</label>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  );
};

export default Invester;
