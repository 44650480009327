import styled from 'styled-components';

export const Section = styled.section`
  padding: 100px 0 0;
  // background: var(--surface-card);

  .current-visitors {
    font-size: 40px;
    text-align: center;
  }

  iframe {
    border: none;
  }
`;
