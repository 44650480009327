import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0 80px;

  &.light {
    background: var(--surface-card);
  }

  @media (max-width: 576px) {
  }
`;

export const Item = styled.div`
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  border: solid 1px rgba(255, 255, 255, 0.25);
  text-align: center;
  padding: 30px 0 40px;
  margin: 0 0 30px;
  cursor: pointer;

  h4 {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 0.8px;
    color: var(--primary-color);
    margin: 0 0 20px;
  }
  h3 {
    font-size: 42px;
    @media (min-width: 1200px) {
      font-size: 52px;
    }
    font-weight: 400;
    line-height: 0.92;
    margin: 0 0 10px;
  }
  .pricing {
    font-size: 15px;
    line-height: 18px;
    margin: 0 0 20px;
    color: #fff;
  }
  span {
    font-size: 14px;
    display: inline-block;
    color: var(--primary-color);
    margin: 0 0 20px;
  }
  label {
    display: block;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    width: 100%;
    color: #fff;
    font-weight: normal;
    letter-spacing: 0.5px;
    background-image: linear-gradient(117deg, var(--primary-color), var(--secondary-color));
    background-repeat: no-repeat;
    background-position: left;
    div {
      color: #e80074;
      line-height: 16px;
      font-size: 12px;
      letter-spacing: 0px;
      margin: auto;
      display: block;
    }
  }

  @media (max-width: 576px) {
  }
`;
