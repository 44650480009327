import { ParallaxBanner as P } from 'react-scroll-parallax';
import styled from 'styled-components';
import { Section as S } from 'components/Section';

export const Section = styled(S)`
  position: relative;
  padding: 100px 0;
  @media screen and (min-width: 960px) {
    padding: 200px 0;
  }
  h2 {
    font-size: 42px;
  }

  &.light {
    background: var(--surface-card);
  }

  .container {
    z-index: 1;
    position: relative;

    > * {
      z-index: 1;
    }
  }

  .p-progressbar {
    border-radius: 6px;
    height: 12px;
  }

  .total {
    text-align: right;
    font-size: 34px;
  }

  .goal-stat {
    width: 33.33%;
    //height: 30px;
    padding: 10px;
    float: left;
    margin: 30px 0 0;
    color: #fff;
    text-align: center;

    @media only screen and (max-width: 640px) {
      width: 50%;
      text-align: center;
    }
  }

  .goal-number,
  .goal-label {
    display: block;
    color: #fff;
    font-size: 20px;
  }

  .goal-number {
    font-weight: bold;
  }

  @media (max-width: 576px) {
  }
`;

export const ParallaxBanner = styled(P)`
  padding: 100px 0;

  div:first-child {
    background-size: contain !important;
    background-repeat: no-repeat;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #080718;
  }

  .container {
    z-index: 1;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    > * {
      z-index: 1;
    }
  }
`;
