import React from 'react';
import { Container } from 'components/container';
import data from './data.json';
import { Section, ItemWrap } from './index.styled';
import { SectionHeader } from 'components/Section';
import parser from 'html-react-parser';

const Item = ({ img, steps, title, text }) => (
  <ItemWrap>
    <div className="service_icon">
      <img draggable="false" src={img} className="colored-icon" alt="" />
      <span className="step-num">{steps}</span>
    </div>
    <h6>{parser(title)}</h6>
    <p>{parser(text)}</p>
  </ItemWrap>
);

const HowWorks = () => {
  return (
    <Section>
      <Container>
        <div className="grid mb-8 px-4">
          <div className="col-12 md:col-6 md:col-offset-3 text-center">
            <SectionHeader
              label=""
              title="3 Simple steps"
              text="Please follow our process of determining the amount you want to invest. Enter your information below and confirm with our Transfer Agent and our SEC Filed site!"
            />
          </div>
        </div>

        <div className="grid">
          {data.map((item, i) => (
            <div className="col-12 lg:col-4" key={i}>
              <Item key={i} {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default HowWorks;
