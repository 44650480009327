import { Container } from 'components/container';
import data from './data.json';
import { Section } from './index.styled';
import parse from 'html-react-parser';
import { SectionHeader } from 'components/Section';

const Definations = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 text-center">
            <SectionHeader
              label="DEFINITIONS"
              title="Individual and Joint income"
              text="Income means adjusted gross income as reported for federal income tax purposes"
            />
          </div>
        </div>

        <div className="wrap">
          {data.map(text => {
            return <p className="text-justify">{parse(text)}</p>;
          })}
        </div>
      </Container>
    </Section>
  );
};

export default Definations;
