import { Item, Section } from './index.styled';
import { Container } from 'components/container';
import { SectionHeader } from 'components/Section';
import { Divider } from 'components/divider';
import { Image } from 'primereact/image';
import data from './data.json';
// import DropContainer from '/assets/images/drop_container.jpg';

const ItemTemplate = ({ img, label, text }) => {
  return (
    <Item className="align-items-start">
      <Image src={img} />
      <div className="details">
        <h6>{label}</h6>
        <p>{text}</p>
      </div>
    </Item>
  );
};

const Approach = () => {
  return (
    <Section>
      <Divider bottom />
      <img src="/assets/images/drop_container.jpg" alt="drop container" className="w-full" />
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 text-center">
            <SectionHeader label={data.label} title={data.title} className='my-4'/>
          </div>
        </div>
        <div className="grid m-0 my-0">
          {data.services.map((item, i) => (
            <div key={i} className="col-12 md:col-6">
              <ItemTemplate {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Approach;
