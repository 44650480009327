import React from 'react';
import { Section } from './index.styled';
import { SectionHeader } from 'components/Section';
import { Container } from 'components/container';

const CurrentVisitors = () => {
  return (
    <Section>
      <img src="/assets/images/drop_crates.jpg" alt="Drop DISC " className="w-full" />
      <SectionHeader label="" title="CURRENT WEB VISITORS" className="my-4 text-center" />
      <Container className='mb-6'>
        <p>
          In our intergalactic future, a a DROP Unit™ or Digital Interplanetary Supply Crate or ("DISC") is not merely a
          purchase - it's a lifeline, a bundle of essential resources necessary for interstellar pioneers seeking to
          settle and terraform new planets. These DISC’s are issued and managed by various Interstellar Resource
          Entities (IREs), each specializing in a different resource type.
        </p>
        <p>
          Each DISC, contains a diverse mix of physical assets critical to terraforming and survival. There's not only
          gold, silver and copper utilities there will be - packaged environmental, social and governance (ESG)
          resources to ensure oxygen production and building materials, similar to our earthly forestry. These trees are
          bioengineered to grow in alien soils, creating forests to transform carbon dioxide into breathable air.
        </p>
        <p>
          In strategic quantities, the DISC’s also contain precious “space metals” – copper, silver, and gold. Copper is
          essential for creating power lines and electronics, silver has numerous industrial applications, and gold is
          used for advanced computing and deep-space communication devices.
        </p>
        <p>
          When intergalactic settlers purchase a DROP Unit™ or DISC, they invest in diverse essential resources. The
          resources within these units lay the foundation for life and civilization on a new planet, establishing the
          basis for self-sustaining ecosystems and advanced settlements.
        </p>
        <p>
          This concept creates an exciting blend of resource management, frontier survival, and deep-space finance. It
          creates intriguing possibilities for conflicts and alliances around acquiring and controlling these vital
          DISC’s and Planetary ecosystems. Can't wait to see how our interstellar settlers would thrive and build their
          new home with their DISC’s!
        </p>
      </Container>
      <iframe
        src="https://portal.theellipsis.exchange/isps/index.html?visit"
        title="Current web visitors"
        width="100%"
        height="100%"
        className='block'
        style={{minHeight: '400px'}}
      />
    </Section>
  );
};

export default CurrentVisitors;
