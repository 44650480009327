import { Container } from 'components/container';
import { Section } from './index.styled';
import data from './data.json';
import { SectionHeader } from 'components/Section';
import ContractCard from 'components/contract';

const Technology = ({ light = false }) => {
  return (
    <Section className={light && 'light'}>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2">
            <SectionHeader className="text-center" label={data.label} title={data.title} text={data.text} />
          </div>
        </div>

        <div className="grid mt-6">
          {data.items.map((item, i) => (
            <div className="col-12 md:col-6 flex" key={i}>
              <ContractCard {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Technology;
