import React from 'react';
import { PhoneInputWrapper } from './indext.styled';
import ReactPhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface IPhoneInputProps {
  inputProps: object;
  onChange: (value: string, countryCode: string) => void;
  value: string;
  className?: string;
}

const PhoneInput: React.FC<IPhoneInputProps> = ({ inputProps, onChange, value, className }) => {
  return (
    <PhoneInputWrapper>
      <ReactPhoneInput
        enableSearch
        inputProps={inputProps}
        inputStyle={{
          color: 'var(--text-color)',
          width: '100%',
          background: 'var(--form-background)',
          border: `1px solid ${className === 'p-invalid' ? 'var(--error)' : 'var(--surface-border)'}`,
          padding: '0.5rem 0.5rem 0.5rem 3.5rem',
          fontSize: '1rem',
          height: '3.2rem',
        }}
        dropdownStyle={{
          color: 'var(--text-color)',
          background: 'var(--surface-overlay)',
          border: '1px solid var(--surface-border)',
          padding: '0.5rem',
          fontSize: '1rem',
        }}
        searchClass="search-container"
        searchStyle={{
          color: 'var(--text-color)',
          background: 'var(--surface-overlay)',
          border: '1px solid var(--surface-border)',
          padding: '0.5rem',
          fontSize: '1rem',
          height: '3.2rem',
        }}
        buttonClass="button-container"
        buttonStyle={{
          color: 'var(--primary-color)',
          border: `1px solid ${className === 'p-invalid' ? 'var(--error)' : 'var(--surface-border)'}`,
          fontSize: '1rem',
        }}
        country={'us'} // should only be the start country
        preferredCountries={['us', 'ca']}
        value={value}
        onChange={(value: string, data: CountryData) => {
          onChange(value.replace(/\D/g, ''), data.countryCode);
        }}
      />
    </PhoneInputWrapper>
  );
};

export default PhoneInput;
