import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  background: var(--surface-card);

  .heading {
    p {
      text-align: center !important;
    }
  }

  .wrap {
    p {
      margin: 0;
    }
  }

  .item {
    position: relative;
    padding: 30px 25px 15px;
    width: 100%;
    background: var(--surface-card);

    &.last {
      &:after {
        display: none;
      }
    }

    ul {
      padding-left: 20px;

      li {
        line-height: 1.3;
        margin-bottom: 15px;
        color: var(--text-color-secondary);
        font-size: 16px;
        text-align: justify;
      }
    }
  }

  @media (max-width: 576px) {
  }
`;
