import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { clearInterval } from 'timers';
import { Wrapper } from './index.styled';

interface Props {
  loading: boolean;
}

const Redirect = ({ loading }: Props) => {
  const [isProgressDone, setIsProgressDone] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let isRunning = true;

    const progressInterval = setInterval(() => {
      if (isRunning) {
        setProgress(prevProgress => {
          if (prevProgress === 400) isRunning = false;
          return prevProgress + 10;
        });
      }
    }, 10);

    return () => {
      isRunning = false;
      if (progressInterval) clearInterval(progressInterval);
    };
  }, []);

  useEffect(() => {
    if (progress === 400 && !loading) {
      setIsProgressDone(true);
    }
  }, [progress, loading]);

  return (
    <Wrapper className={classNames('pre-loading', { isdone: isProgressDone })}>
      <div className="flex flex-col justify-center items-center">
        <div className="loading">
          <span>Connecting to secure investment portal. Go to dropmarket once you have created your dropid.</span>
        </div>
        <img className="gif" alt="logo" src="/assets/images/logo-light.png" style={{ height: '100px' }} />
      </div>
    </Wrapper>
  );
};

export default Redirect;
