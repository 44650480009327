import { Section } from './index.styled';
import containerPlanet from './drop_surface.jpg';

const HomePageBanner = () => {
  return (
    <Section>
      <img src={containerPlanet} alt="Planet" className="w-full" />
    </Section>
  );
};

export default HomePageBanner;
