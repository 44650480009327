import PreLoading from 'components/pre-loading';
import Banner from 'components/banner';
import RegisterForm from 'components/register-form';
import Intro from '../../components/intro';
import VideoWrapper from 'components/video-wrapper';
import bannerData from './data/bannerData.json';
import introData from './data/introData.json';
import TokenInfo from './token-info';
import WhitepaperDownload from 'components/whitepaper-download';
import UnitPrices from '../../components/unit-prices';
import TokenAssets from './token-assets';
import SaftAgreement from 'components/SaftAgreement';

const TokensPage = () => {
  return (
    <>
      <PreLoading label="CREDITS" />
      <Banner {...bannerData} />
      <TokenInfo />
      <SaftAgreement />
      {/* <img src="/assets/images/unit/safet_billet.png" alt="Safet" className="w-full block" /> */}
      <UnitPrices light />
      <WhitepaperDownload />
      <TokenAssets />
      <Intro {...introData} light />
      <VideoWrapper />
      <RegisterForm light />
    </>
  );
};
export default TokensPage;
