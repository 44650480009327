import PreLoading from 'components/pre-loading';
import Banner from '../../components/banner';
import RegisterForm from 'components/register-form';
import Intro from 'components/intro';
import introData from './data/introData.json';
import crwdSeparator from './data/crwdSeparator.json';
import bannerData from './data/bannerData.json';
import HowWorks from './how-works';
import WhitepaperDownload from 'components/whitepaper-download';
import CrwdworldSeparator from 'components/crwdworld-separator';
import UnitPrices from 'components/unit-prices';
import CurrentVisitors from './current-visitors';
import Fund from './fund';
// import CountUP from 'components/count-up';
import BigData from './big-data';
import HomePageBanner from './banner';
import Approach from './approach';
// import styled from 'styled-components';
import VideoWrapper from 'components/video-wrapper';
import { useUnitPurchaseStatusQuery } from 'graphql/generated/resourceApi';
import EcoBanner from '../ecosystem/banner/index';
import EcoBannerData from '../ecosystem/data/bannerData.json';
import { useClientContext } from 'contexts/ClientContext';

// const HomeVideoWrapper = styled.div`
//   z-index: 1;
//   position: relative;
//   padding-top: 56%;
//   width: 100%;
//   .brightcove-react-player-loader {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;

//     video-js {
//       width: 100%;
//       height: 100%;
//     }
//   }
// `;
const HomePage = () => {
  const { client } = useClientContext();
  const statusState = useUnitPurchaseStatusQuery(client);
  const status = statusState.data?.unitPurchaseStatus;

  const regDResults = {
    totalRound: 44440000,
    raisedAmount: status?.amount || 0,
    investorCounts: status?.count || 0,
  };
  return (
    <>
      <PreLoading label="WELCOME" />
      <Banner {...bannerData} />
      <Fund
        title="REG D OFFERING/SAFT AGREEMENT RESULTS"
        totalRound={regDResults.totalRound}
        raisedAmount={regDResults.raisedAmount}
        investorCounts={regDResults.investorCounts}
      />
      <RegisterForm light />
      <BigData />
      <HomePageBanner />
      <UnitPrices />
      {/* <CountUP light /> */}
      <Approach />
      <CurrentVisitors />
      {/* <img src={containerPlanet} alt="Planet" /> */}
      <EcoBanner {...EcoBannerData} />
      <HowWorks />
      <WhitepaperDownload />
      <CrwdworldSeparator {...crwdSeparator} />
      <Intro {...introData} light />

      {/* <Fund
        title="ACTIVE NOW <br/> REG CF CURRENT RESULTS"
        totalRound={regCFResults.totalRound}
        raisedAmount={regCFResults.raisedAmount}
        investorCounts={regCFResults.investorCounts}
      /> */}
      <VideoWrapper />
    </>
  );
};
export default HomePage;
