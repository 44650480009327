import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  // ::selection {
  //   text-shadow: none;
  //   color: #fff;
  //   background: #555;
  // }

  ::-webkit-scrollbar {
    width: 8px;
    height:8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #333;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  body{
    color: var(--text-color);
    background: var(--surface-ground);
    line-height: 1.5;
    font-weight: 400;
    overflow-x: hidden !important;
    font-family: var(--font-family);
  }

  @font-face {
    font-family: 'Neuropol X light';
    src: url('/assets/fonts/Neuropol X Lite.ttf');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neuropol X bold';
    src: url('/assets/fonts/Neuropol X Bold.ttf');
    font-weight: normal;
    font-style: normal;
  }

  .crwd-font {
    font-size: 22px;
    font-weight: 100;
    font-family: 'Neuropol X light';
    text-transform: lowercase;

    b {
      font-family: 'Neuropol X bold';
    }
  }
  h3 .crwd-font {
    font-size: 2rem;
  }
  h2 .crwd-font {
    font-size: 60px;
  }

  p, ul.bullets, ol.numbers {
    color: var(--text-color-secondary);
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  .wrap{
    padding-top:88px;
  }

  input:-webkit-autofill{
    color: var(--text-color) !important;
    background-color: var(--surface-section) !important;
  }



  .p-scrolltop{
    padding: 0 30px;
    right: 40px;
    bottom: 40px;


    .p-scrolltop-icon {
      font-size: 12px;
    }
  }

  .p-autocomplete-panel{
    .p-virtualscroller{
      ::-webkit-scrollbar-track {
        background: #fff !important;
      }
    }
  }

  .pi-times-circle {
    &.error{
      color:var(--error) !important;
    }
  }

  .pi-check-circle{
    &.success{
      color:var(--success-color) !important;
    }
  }

  .btn-shadow{
    box-shadow: 0 2px 0 var(--primary-color-lighter1), 0 61px 111px rgb(47 50 69 / 15%), 0 39.0082px 46.3731px rgb(47 50 69 / 12%),
      0 23.3274px 24.7933px rgb(47 50 69 / 10%), 0 12.9302px 13.8989px rgb(47 50 69 / 9%);
  }

  .p-error{
    font-weight:700;
  }

  .p-fileupload{
    border-radius: 50%;
    box-shadow: 0px 0px 20px rgb(110 135 171 / 50%);

    .p-fileupload-choose{
      min-width: 1rem;
      // border-radius:50%;
      padding: 0.686rem 0.7rem;
      color: #212121;
      background: #fff;

      .p-button-icon{
        margin:0;
      }
      .p-button-label{
        display:none;
      }
    }
  }
  .fit-absolute {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    object-fit: cover;
    height:100%;
  }
  .index-1 {
    z-index: 10;
  }
`;
