import styled from 'styled-components';

export const Section = styled.section`
  overflow: hidden;
  padding: 50px 0;

  &.light {
    background: var(--surface-card);
  }

  img {
    width: 88%;
    margin: 0 auto;
    display: flex;
    &.big {
      height: calc(100% - 10px);
      width: 100%;
      margin-top: -10px;
    }
    &.small {
      width: 200px;
      position: relative;
      z-index: 1;
    }
  }

  @media (max-width: 576px) {
    .grid {
      gap: 20px;
      flex-wrap: wrap;
    }
  }
`;
