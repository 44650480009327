import { Button } from 'primereact/button';
import styled from 'styled-components';

export const BTN = styled(Button)`
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  transition: all 0.4s;
  padding: 15px 35px;
  border: 1px solid transparent;

  &.light {
    box-shadow: none !important;
    background: #fff;
    color: #121212;

    &.p-button-outlined {
      background: transparent;
      border: 1px solid #fff;
      color: #fff;
    }

    &:hover {
      border: 1px solid #fff !important;
      background: #fff !important;
      color: var(--secondary-color-text) !important;
    }
  }

  &:active {
    box-shadow: none !important;
    opacity: 0.6;
  }

  @media (max-width: 576px) {
    padding: 10px 25px;
  }
`;
