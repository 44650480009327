import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { calcTimeDelta, parseTimeDelta } from './utils';
import styles from './styles.module.css';
import FlipClockDigit from './FlipClockDigit';
import { FlipClockCounterProps, FlipClockCounterState, FlipClockCounterRenderProps } from './types';

function FlipClockCounter({
  time,
  type = 'down',
  className,
  children,
  onComplete,
  onTick,
  ...other
}: FlipClockCounterProps) {
  const CounterRef = React.useRef(0);
  function clearTimer() {
    window.clearInterval(CounterRef.current);
  }

  const constructState = useCallback((): FlipClockCounterState => {
    const timeDelta = calcTimeDelta(time, type);
    return {
      timeDelta,
      completed: timeDelta.total === 0,
    };
  }, [time, type]);
  const [state, setState] = React.useState<FlipClockCounterState>(constructState);

  const tick = useCallback(() => {
    const newState = constructState();
    setState(newState);
    onTick && onTick(newState);
    if (newState.completed) {
      clearTimer();
      onComplete && onComplete();
    }
  }, [constructState, onComplete, onTick]);

  React.useEffect(() => {
    clearTimer();
    CounterRef.current = window.setInterval(tick, 1000);

    return () => clearTimer();
  }, [time, tick]);

  const renderProps = useMemo<FlipClockCounterRenderProps>(() => {
    const { timeDelta } = state;
    return {
      ...timeDelta,
      formatted: parseTimeDelta(timeDelta, type),
    };
  }, [state, type]);

  if (state?.completed) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const { days, hours, minutes, seconds } = renderProps.formatted;
  const labels = ['days', 'hours', 'minutes', 'seconds'];

  return (
    <div {...other} className={clsx(styles.fcc__container, className)}>
      {[days, hours, minutes, seconds].map((item, idx) => {
        return (
          <React.Fragment key={`digit-block-${idx}`}>
            <div className={styles.fcc__digit_block_container}>
              <div className={styles.fcc__digit_block_label}>{labels[idx]}</div>
              {item.current.map((cItem, cIdx) => (
                <FlipClockDigit key={cIdx} current={cItem} next={item.next[cIdx]} />
              ))}
            </div>
            {idx < 3 && <div className={styles.fcc__colon}>:</div>}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default FlipClockCounter;
