import { Container } from 'components/container';
import { SectionHeader } from 'components/Section';
import parse from 'html-react-parser';
import data from './data.json';
import { Item, Section } from './index.styled';

const PricingItem = ({ round, dollar, tokenWorth, noOfTokens, raise }) => {
  return (
    <Item className="pricing-item mx-auto">
      <h4>Round {parse(round)}</h4>
      <h3>
        <strong className="xzc-1-month">{dollar}</strong>
      </h3>
      <span>{tokenWorth}</span>
      <div className="pricing">{noOfTokens}</div>
      <label>
        <strong>{parse(raise)}</strong>
      </label>
    </Item>
  );
};

const UnitPrices = ({ light = false }) => {
  return (
    <Section className={light && 'light'}>
      <Container>
        <div className="grid mb-8">
          <div className="col-12 md:col-6 md:col-offset-3 text-center">
            <SectionHeader
              label=""
              title="OUR ROUND PRICING"
              text="Welcome to our Offering. The Offering is expected to have at least four distinct rounds. Listed below are the details of each round. Additionally, you will see the restrictions and the timelines for each round."
            />
          </div>
        </div>

        <div className="grid align-items-center">
          {data?.map((item, key) => (
            <div key={key} className="col-12 md:col-6 lg:col-3">
              <PricingItem
                round={item.round}
                dollar={item.dollar}
                noOfTokens={item.noOfTokens}
                tokenWorth={item.tokenWorth}
                raise={item.raise}
              />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default UnitPrices;
