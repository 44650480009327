import styled from 'styled-components';
import parse from 'html-react-parser';

interface Props {
  bg?: string;
}

const SectionWrap = styled.section<Props>`
  background-image: ${({ bg }) => (bg ? `url(${bg})` : '')};
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 576px) {
  }
`;

const Heading = styled.div`
  h3 {
    display: inline-block;
    // background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
    background: linear-gradient(to right, #dfdfdf, #f4f4f4, #00cfe5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  a {
    color: var(--white-color);
  }

  h2 {
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 35px;
  }

  p {
    margin: 0rem;
    text-align: justify;
  }

  @media (max-width: 576px) {
  }
`;

const Heading2 = styled.div`
  h3 {
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 60px;
    // background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
    background: linear-gradient(to right, #dfdfdf, #f4f4f4, #00cfe5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }
  a {
    color: var(--white-color);
  }

  h2 {
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 25px;
  }

  p {
    margin: 0rem;
    text-align: justify;
  }

  @media (max-width: 576px) {
  }
`;

export const Section = ({ bg = '', children, className = '' }) => {
  return (
    <SectionWrap className={className} bg={`${process.env.PUBLIC_URL}${bg}`}>
      {children}
    </SectionWrap>
  );
};

export const SectionHeader = ({ className = '', label = '', title = '', text = '' }) => {
  return (
    <Heading className={'heading ' + className}>
      {label && <h3>{parse(label)}</h3>}
      {title && <h2>{parse(title)} </h2>}
      {text && <p>{parse(text)}</p>}
    </Heading>
  );
};

export const SectionHeader2 = ({ className = '', label = '', title = '', text = '' }) => {
  return (
    <Heading2 className={'heading2 ' + className}>
      {label && <h3>{parse(label)}</h3>}
      {title && <h2>{parse(title)} </h2>}
      {text && <div>{parse(text)}</div>}
    </Heading2>
  );
};
