import { Container } from 'components/container';
import { GradiantBG } from 'components/text';
import data from './data.json';
import { Section } from './index.styled';
import parse from 'html-react-parser';
import ContractCard from 'components/contract';

const SmartContract = () => {
  return (
    <Section>
      <Container>
        <div className="grid asset-class px-4">
          <div className="col-12 lg:col-6">
            <GradiantBG className="mb-2">UTILITY OF WORTH</GradiantBG>
            <h2 className="title">A NEW ERA IN FINANCE</h2>

            <p>
              Welcome to our{' '}
              <span className="crwd-font">
                drop<b>unit</b>™
              </span>{' '}
              offering on <span className="crwd-font">protocol</span>!
            </p>

            <h4>
              What if you could own a piece of the future? <br /> What if you could build your next castle not on sand
              but on the bedrock of a modern blockchain ecosystem?
            </h4>

            <p>
              The first Internet Gold Rush Made Millionaires; the second wave minted billionaires. But the third wave,
              Web 3.0 is gathering now, and anyone can get in on the action.
            </p>

            <p>
              It is commonly agreed that Blockchain has potential applications far beyond bitcoin and cryptocurrency. At
              DROP, we agreed with this mindset and built a better blockchain. DROP developed a NexGen cryptographic
              cloud that allows developers to bring their favorite programming language and consumers and companies to
              contribute their own data.
            </p>
            <p>
              Our Vogon Blockchain is a cutting edge business process improvement software and collaborative technology
              that will radically lower the cost of trust between consumers, businesses, banks, and governments.
            </p>
            <p>
              This foundational technology has enabled us to build{' '}
              <span className="crwd-font">protocol, an ecosystem</span> focused on an egalitarian economy that rewards
              users that share their data. As we all know, data is the new oil -{' '}
              <span className="crwd-font">protocol</span> is the ecosystem ready to refine that oil into a fuel that
              powers prosperity.
            </p>
            <p>
              To move into the future we returned to a more simple past. We emulate many facets of the Buttonwood
              Agreement of 1792 focused on asset-backed trades and open, open stable markets. We have focused on
              improving traditional financial tools by returning them to their foundations. We also have ensured we
              follow regulated processes, guaranteeing user security.
            </p>
          </div>
          <div className="col-12 lg:col-6 asset-info">
            {data.slice(0, 1).map((item, key) => (
              <div key={key}>
                <img src={item.img} alt={item.title} className="w-full" />
                <h3 className="text-center">{parse(item.title)}</h3>
                <h4 className="text-center" style={{ fontSize: '30px' }}>
                  {parse(item.subtitle)}
                </h4>
                {parse(item.text)}
              </div>
            ))}
          </div>
        </div>
      </Container>

      <img src="/assets/images/drop_banner.jpg" alt="Vogan" className="w-full block" />

      <Container>
        <div className="grid mt-8">
          {data.slice(1, 5).map((item, key) => (
            <div className="col-12 lg:col-6">
              <ContractCard key={key} {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default SmartContract;
