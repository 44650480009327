import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  background: var(--surface-card);

  .heading {
    p {
      text-align: center !important;
    }
  }

  p {
    span {
      font-size: 25px;
      color: var(--primary-color);
    }
  }
  .wire-ins {
    .heading {
      margin-bottom: 40px;
      text-align: center;
    }
    h4,
    p {
      margin-right: 10px !important;
      display: inline-block;
      margin: 6px 0;
    }
  }

  @media (max-width: 576px) {
  }
`;
