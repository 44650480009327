import React, { useEffect, useState } from 'react';
import { Container } from 'components/container';
import data from './data.json';
import { Section } from './index.styled';
import parse from 'html-react-parser';
import { SectionHeader } from 'components/Section';
import { Carousel } from 'primereact/carousel';
import useViewport from 'utils/useViewport';

const ContributionCycle = () => {
  const { width } = useViewport();
  const isMobileWidth = width < 960;
  const [isMobile, setIsMobile] = useState(isMobileWidth);

  useEffect(() => {
    if (isMobileWidth !== isMobile) {
      setIsMobile(isMobileWidth);
    }
  }, [width, isMobile, isMobileWidth]);

  const cardTemplate = (data: any) => {
    return (
      <div className="col-12 flex" key={data.i}>
        <div className={`item rotate-arrow ${data.i === 2 ? 'last' : ''}`}>
          {/* <div className="cycle_icon">
            <span className={`gradient-t ${data.indexColor}`}>{data.index}</span>
          </div> */}
          <h4>{parse(data.label)}</h4>
          <>{parse(data.text)}</>
        </div>
      </div>
    );
  };

  return (
    <Section>
      <Container>
        <div className="grid mb-8">
          <div className="col-12 md:col-8 md:col-offset-2 text-center">
            <SectionHeader
              label="<span className='crwd-font'>drop<b>unit</b>™</span> | THE BACKBONE OF OUR BLOCKCHAIN ECOSYSTEM"
              title="VALUE = UTILITY OF WORTH"
              text="<span className='crwd-font'>drop<b>units</b>™</span> are the utility for DROP's Blockchain Ecosystem. They have worth because of the silver that will back them. <span className='crwd-font'>drop<b>units</b>™</span> are unique because as a foundational utility with a base value, they gain accretive value based on offerings from our blockchain ecosystem. Read below to learn more."
            />
          </div>
        </div>
        {isMobile ? (
          <Carousel value={data} numVisible={1} numScroll={1} itemTemplate={cardTemplate} circular />
        ) : (
          <div className="grid mb-8">
            {data.slice(0, 3).map((item, i) => (
              <div className="col-12 md:col-4 flex" key={i}>
                <div className={`item`}>
                  <h4>{parse(item.label)}</h4>
                  <>{parse(item.text)}</>
                </div>
              </div>
            ))}
            {data.slice(3, 6).map((item, i) => (
              <div className="col-12 md:col-4 flex" key={i}>
                <div className={`item`}>
                  <h4>{item.label}</h4>
                  <>{parse(item.text)}</>
                </div>
              </div>
            ))}
          </div>
        )}
      </Container>
    </Section>
  );
};

export default ContributionCycle;
