import PreLoading from 'components/pre-loading';
import ContributionCycle from './contribution-cycle';
import SmartContract from './smart-contract';
import bannerData from './data/bannerData.json';

import RegisterForm from 'components/register-form';
import BusinessModel from './business-model';
import Banner from './banner';
import Faq from 'pages/about/faq';

const EcosystemPage = () => {
  return (
    <>
      <PreLoading label="ECOSYSTEM" />
      <Banner {...bannerData} />
      <SmartContract />
      <BusinessModel />
      <ContributionCycle />
      <Faq />
      <RegisterForm />
    </>
  );
};
export default EcosystemPage;
