import { Dialog as D } from 'primereact/dialog';
// import ReactPlayerLoader from '@brightcove/react-player-loader';
import styled from 'styled-components';

const Dialog = styled(D)`
  padding: 0 !important;
  box-shadow: none !important;

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    padding: 0px;

    video-js {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }
`;

interface PropsType {
  show: boolean;
  onHide: () => void;
  className?: string;
  videoUrl: string;
}
const PlayerDialog = ({ className = '', show, onHide, videoUrl }: PropsType) => {
  return (
    <Dialog
      visible={show}
      onHide={onHide}
      className={className}
      dismissableMask
      style={{ width: '80vw', height: '85vh', innerWidth: '80vw' }}
    >
      <div className="player-wrapper">
        <video width={'100%'} height={'100%'} autoPlay muted controls>
          <source src="https://storefront-static.crowdpoint.tech/drop/dropprotocol_video.mp4" />
        </video>
      </div>
    </Dialog>
  );
};
export default PlayerDialog;
