import PrimeReact from 'primereact/api';
// import "primereact/resources/themes/md-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import ScrollToTop from './utils/ScrollToTop';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from 'pages/home';
import Header from 'components/header';
import Footer from 'components/footer';
import AboutPage from 'pages/about';
import TeamPage from 'pages/team';
import RoadmapPage from 'pages/roadmap';
import DecentralizedPage from 'pages/decentralized';
import ContactPage from 'pages/contact';
import TokensPage from 'pages/tokens';
import EcosystemPage from 'pages/ecosystem';
import { ClientProvider } from 'contexts/ClientContext';
import PrivacyPage from 'pages/privacy';
import DisclosurePage from 'pages/disclosure';
import { ComingSoon } from 'pages/ComingSoon';

function App() {
  // active ripple effect
  PrimeReact.ripple = true;

  return (
    <>
      <BrowserRouter>
        <ClientProvider>
          <ScrollToTop />
          <Header />
          <div className="wrap">
            <Routes>
              <Route path="" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/ecosystem" element={<EcosystemPage />} />
              <Route path="/team" element={<TeamPage />} />
              <Route path="/roadmap" element={<RoadmapPage />} />
              <Route path="/decentralized" element={<DecentralizedPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/tokens" element={<TokensPage />} />
              <Route path="/credits" element={<TokensPage />} />
              <Route path="/disclosure" element={<DisclosurePage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/coming-soon/:value" element={<ComingSoon />} />

              <Route path="*" element={<Navigate to="" />} />
            </Routes>
          </div>
          <Footer />
        </ClientProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
