// import ReactPlayerLoader from '@brightcove/react-player-loader';
import styled from 'styled-components';
import PreLoading from 'components/pre-loading';
import ContributionCycle from './contribution-cycle';
import Banner from '../../components/banner';
import SmartContract from './smart-contract';
import bannerData from './data/bannerData.json';
import Faq from './faq';
import RegisterForm from 'components/register-form';
import BusinessModel from './business-model';

const VideoWrapper = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  .brightcove-react-player-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video-js {
      width: 100%;
      height: 100%;
    }
  }
`;
const AboutPage = () => {
  return (
    <>
      <PreLoading label="ABOUT" />
      <Banner {...bannerData} />
      <SmartContract />
      <VideoWrapper>
        <video width="100%" height="100%" autoPlay muted controls>
          <source src="https://storefront-static.crowdpoint.tech/drop/dropprotocol_video.mp4" />
        </video>
      </VideoWrapper>
      <BusinessModel />
      <ContributionCycle />
      <Faq />
      <RegisterForm />
    </>
  );
};
export default AboutPage;
