import { Container } from 'components/container';
import { SectionHeader } from 'components/Section';
import { Section } from './index.styled';
import { Accordion, AccordionTab } from 'primereact/accordion';
import data from './data.json';
import parser from 'html-react-parser';

const Faq = () => {
  return (
    <Section id="faq">
      <Container>
        <div className="grid mb-8">
          <div className="col-12 md:col-6 md:col-offset-3 text-center px-4 md:px-0">
            <SectionHeader
              label="DROP Unit™ FAQ"
              title="FREQUENTLY ASKED QUESTIONS"
              text="CHECK OUT OUR KNOWLEDGE BASE ON DROP Protocol™ IN THE MEANTIME WE HAVE ASSEMBLED SOME OF THE QUESTIONS HERE:"
            />
          </div>
        </div>

        <div className="grid align-items-center">
          <div className="col-12 lg:col-6 lg:col-offset-0 md:col-8 md:col-offset-2 sm:col-12 text-center">
            <img src="/assets/images/about/faq.svg" alt="" className="center-block img-responsive" />
          </div>
          <div className="col-12 lg:col-6 md:col-12">
            <Accordion>
              {data.map((item, k) => (
                <AccordionTab header={item.title} key={k}>
                  <p>{parser(item.text)}</p>
                </AccordionTab>
              ))}
            </Accordion>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Faq;
