import PreLoading from 'components/pre-loading';
import Banner from 'components/banner';
import RegisterForm from 'components/register-form';
// import CountDown from 'components/count-down';
import Intro from 'components/intro';
import introData from './data/introData.json';
import ProjectDetails from 'components/project-details';
import projectDetailsData from './data/projectDetails.json';
import UniversalAccess from 'components/universal-access';
import VideoWrapper from 'components/video-wrapper';
import bannerData from './data/bannerData.json';
import WhitepaperDownload from 'components/whitepaper-download';
import CountUP from 'components/count-up';

const ContactPage = () => {
  return (
    <>
      <PreLoading label="CONTACT" />
      <Banner {...bannerData} />
      <WhitepaperDownload />
      <CountUP light />
      <ProjectDetails {...projectDetailsData} />
      <Intro {...introData} />
      <VideoWrapper />
      <UniversalAccess />
      <RegisterForm />
    </>
  );
};
export default ContactPage;
