import styled from 'styled-components';

export const Section = styled.section`
  padding: 60px 0 0;
  // background: var(--surface-card);

  p {
    color: var(--text-color-secondary);
  }

  .grid {
    margin: 40px 0 0;
  }

  .item {
    position: relative;
    padding: 60px 15px 15px;
    text-align: center;
    margin: 30px;
    background: var(--surface-card);

    &.last {
      &:after {
        display: none;
      }
    }

    /* &:after {
      content: unset;
      @media (min-width: 960px) {
        content: '';
        position: absolute;
        top: 47%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: -70px;
        width: 60px;
        height: 20px;
        z-index: 2;
        background: url(/assets/images/about/join-right-arrow.svg) 100%/100% auto no-repeat;
      }
    }

    &.rotate-arrow:after {
      transform: rotate(180deg);
    } */

    .cycle_icon {
      position: absolute;
      background: #191736;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: 50%;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
    .cycle_icon span {
      display: block;
      font-size: 23px;
      font-weight: 600;
    }
    .gradient-t {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      /* text-fill-color: transparent; */
      text-align: center;
      position: relative;
    }
    .gradient-t.orange {
      background-image: linear-gradient(180deg, #fad961 0%, #f76b1c 100%);
    }
    .gradient-t.pink {
      background-image: linear-gradient(0deg, #846ff4 0%, #f17674 100%);
    }
    .gradient-t.blue {
      background-image: linear-gradient(180deg, #17ead9 0%, #6078ea 100%);
    }
    .gradient-t.green {
      background-image: linear-gradient(180deg, #43e695 0%, #3bb2b8 100%);
    }
  }

  .rotate-neg {
    max-height: 60px;
    transform: rotate(180deg);
    max-width: 100%;
    height: auto;
  }

  .rotate {
    max-height: 60px;
    max-width: 100%;
    height: auto;
  }

  h4 {
    line-height: 1.4;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
  }

  p {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.9;
    margin: 0;
    margin-bottom: 10px;
  }

  @media (max-width: 576px) {
  }
  .p-carousel {
  }
`;
